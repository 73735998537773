<template>

  <div class="header flexRowBetween headerPhone">
    <div class="flexRowBetween" style="width:100%;">
      <img class="menu_mobile" :src="$Image('menu-mobile.png')" alt=""   @click="drawersApp">

      <img :src="$Image('logo.png')" alt="" style="cursor: pointer;" @click="logoClick">

      <img v-if="loginShow" class="menu_mobile" :src="$Image('user-mobile.png')" alt=""  @click="drawer=true">
      <div v-else class="dropdownlist_img menu_mobile_name flexColCenter" @click="drawer=true">
        <span>{{ firstToUpper(userName) }}</span>
      </div>
    </div>

    <!-- <div class="headerPhone_l"></div> -->

    <div class="flexRowCenter headerPhoneTop">
      <!-- 未登录 -->
      <div v-if="loginShow" class="no_login flexRowCenter headerPhoneDropdown" @click="openLogin">
          <span>
           <!-- {{ $t('Login') }} -->
          </span>
          <div class="sign_up normal_btn">
            {{ $t('Login/Sign Up') }}
          </div>
      </div>
      <!-- //// -->

      <!-- 登录 -->
      <div v-else class="header_login flexRowCenter">
        <div class="upgrade_btn plain_btn" @click="onUpgrade">
          {{ $t('Upgrade') }}
        </div>
        <div class="header_download_app normal_btn" @click="onCredits">
            {{ $t('Get Free Credits') }}
        </div>
        <div class="header_l"></div>
        <div class="flexColStart headerPhoneRow">
          <p class="header_sub">
            {{ $t('Credit Balance:') }}
            <span class="header_num">{{credits.SubscriptCredit}}</span>
          </p>
        </div>
        <div class="header_l"></div>
        <el-dropdown trigger="click" 
          @command="selectMenu"
          @visible-change="iconChange"
          class="dropdownlist headerPhoneDropdown"
          >
          <div class="el-dropdown-link flexRowCenter" style="cursor:pointer;">
            <div class="dropdownlist_img flexRowCenter">
              <span>
                {{ firstToUpper(userName) }}
              </span>
            </div>
            <span class="dropdownlist_span">{{ userName }}</span>
            <i :class="icontype ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(dropdown, i) in dropdownList"
                :key="i"
                :divided="dropdown.img === 'sign_out.png'"
                :command="dropdown.click">
              <div class="flexRowStart" style="width:150px;box-sizing:border-box;">
                <img :src="$Image(dropdown.img)" alt style="padding: 0 6px;">
                <span :title="dropdown.str" style="color: #626262;">{{dropdown.str}}</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!-- //// -->

      <!-- 多语言 -->
      <el-select class="headerSelect" v-model="defaultLanguage" placeholder="请选择" size="mini" style="width: 135px;" @change="handleLanguageChange">
        <el-option
          v-for="item in countryList"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <!-- //// -->
      <div class="header_download_app normal_btn" @click="onDownloadApp">
          {{ $t('Download App') }}
      </div>
    </div>

    <!-- 移动端 -->
    <el-drawer
      :visible.sync="drawer"
      :direction="'rtl'"
      :with-header="false"
      :before-close="handleClose">

      <div class="header_menu_mobile flexColCenter">

        <!-- 未登录 -->
        <div v-if="loginShow" class="no_login flexRowCenter" @click="openLogin">
            <div class="sign_up normal_btn">
              {{ $t('Login/Sign Up') }}
            </div>
        </div>
        <!-- //// -->

        <!-- 登录 -->
        <div v-else class="header_login flexColCenter">
          <div class="dropdownlist_img flexColCenter">
            <span>
              {{ firstToUpper(userName) }}
            </span>
          </div>
          <span class="dropdownlist_span">{{ userName }}</span>

          <div class="flexColStart headerPhoneRow">
            <p class="header_sub">
              {{ $t('Credit Balance:') }}
              <span class="header_num">{{credits.SubscriptCredit}}</span>
            </p>
          </div>

          <div class="flexRowCenter sign_password">
            <div class="flexRowStart" style="box-sizing:border-box;" @click="onChangePassword">
              <img :src="$Image('change_password.png')" alt style="padding: 0 6px;">
              <span class="change_password">{{ $t('Change Password') }}</span>
            </div>

            <div class="line_l"></div>

            <div class="flexRowStart" style="box-sizing:border-box;" @click="onLogout">
              <img :src="$Image('sign_out.png')" alt style="padding: 0 6px;">
              <span class="change_password">{{ $t('Sign out') }}</span>
            </div>
          </div>

          <div class="flexRowCenter upgrade_credits_btn">
            <div class="upgrade_btn plain_btn" @click="onUpgrade">
              {{ $t('Upgrade') }}
            </div>
            <div class="normal_btn upgrade_btn" @click="onCredits">
                {{ $t('Get Free Credits') }}
            </div>
          </div>

          <div class="header_l"></div>

        </div>
        <!-- //// -->

        <div class="header_line"></div>
        <!-- 多语言 -->
        <el-select class="headerSelect" v-model="defaultLanguage" placeholder="请选择" size="medium" @change="handleLanguageChange">
          <el-option
            v-for="item in countryList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <!-- //// -->
        <div class="header_download_app normal_btn" @click="onDownloadApp">
            {{ $t('Download App') }}
        </div>

      </div>

    </el-drawer>
    <!-- 移动端 -->

    <!-- <div class="headerPhone_l"></div> -->

    <LoginRegisterView @loginSuccess=loginSuccess></LoginRegisterView>
    <ChangePasswordView></ChangePasswordView>


  </div>

</template>

<script>
import { mapState } from 'vuex'
import bus from '@/assets/js/bus'
import * as Mutations from '@/store/mutations.js'
import LoginRegisterView from './LoginRegister.vue'
import ChangePasswordView from './ChangePassword.vue'
import { Logout } from '@/assets/js/request/api'
import { getCookie, setCookie } from '@/assets/js/utils/cookie'

export default {
  name: 'Header',
  data() {
    return {
      icontype: false,
      drawer: false,
      dropdownList:
      [
        { img: 'change_password.png', str: this.$t('Change Password'), click: 'onChangePassword' },
        { img: 'sign_out.png', str: this.$t('Sign out'), click: 'onLogout' }
      ],
      countryList: [
        {
          value: 'de',
          label: this.$t('Deutsch')
        }, 
        {
          value: 'en',
          label: this.$t('English')
        }, 
        {
          value: 'fr',
          label: this.$t('Français')
        }, 
        {
          value: 'ja',
          label: this.$t('Japanese')
        },
        {
          value: 'es',
          label: this.$t('Español')
        }
      ],
      defaultLanguage: 'en'
    }
  },
  components: {
    LoginRegisterView,
    ChangePasswordView
  },
  computed: {
    ...mapState([
      'credits',
      'loginShow',
      'userName'
    ])
  },
  methods: {
    handleClose(done) {
      done()
    },
    onDownloadApp () {
      const lang = getCookie('lang')
      console.log('lang', lang)
      switch (lang) {
        case 'en':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'de':
          window.open('https://www.avclabs.de/download.html')
          break
        case 'fr':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'es':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'ja':
          window.open('https://www.avclabs.jp/download.html')
          break
        default:
          window.open('https://www.avclabs.com/download.html')
          break
      }
    },
    onUpgrade () {
      this.drawer = false
      this.$router.push({path:'/upgrade'})
      // window.location.href = 'https://www.avc.ai/upgrade.html#!'
    },
    onCredits () {
      this.drawer = false
      this.$router.push({path:'/get-free-credits'})
      // window.location.href = 'https://www.avc.ai/get-free-credits.html'
    },
    loginSuccess (s) {
      this.$emit('loginSuccessApp', s)
    },
    drawersApp () {
      bus.$emit('drawersApp')
    },
    firstToUpper (str) {
      if (str) {
        let a = str.trim().toLowerCase().replace(str[0], str[0].toUpperCase()).substr(0, 1)
        return a
      }
    },
    onChangePassword () {
      this.drawer = false
      this.$modal.show('ChangePassword')
    },
    Upgrade () {
      window.open('https://www.avclabs.com/price-photo-online.html')
    },
    logoClick () {
      const lang = getCookie('lang')
      console.log('lang', lang)
      switch (lang) {
        case 'en':
          window.open('https://www.avclabs.com/')
          break
        case 'de':
          window.open('https://www.avclabs.de/')
          break
        case 'fr':
          window.open('https://www.avclabs.com/fr/')
          break
        case 'es':
          window.open('https://www.avclabs.com/es/')
          break
        case 'ja':
          window.open('https://www.avclabs.jp/')
          break
        default:
          window.open('https://www.avclabs.com/')
          break
      }
    },
    openLogin() {
      this.drawer = false
       this.$store.commit(Mutations.setWorkspaceState, false)
      this.$modal.show('LoginRegister', {
        loginShow: true
      })
    },
    selectMenu (c) {
      switch (c) {
        case 'onHelp':
          window.open('https://www.avclabs.com/contact.html')
          break
        case 'onLogout':
          this.onLogout()
          break
        case 'onChangePassword':
          this.onChangePassword()
          break
        default:
          break
      }
    },
    async onLogout () {
      this.$router.push({path: '/'})
      this.$store.commit(Mutations.setWorkspaceState, false)
      this.$store.commit(Mutations.setLogin, true)
      this.$store.commit(Mutations.setUserId, '')
      sessionStorage.removeItem('Email')
      sessionStorage.removeItem('Credits')
      sessionStorage.removeItem('Sid')
      localStorage.removeItem('queryParam')
      this.$emit('onLogoutLoading', true)
      await Logout()
      location.reload()
    },
    iconChange (type) {
      if (type) {
        this.icontype = true
      } else {
        this.icontype = false
      }
    },
    handleLanguageChange(val) {
      console.log('languageChange to:', val)
      this.$i18n.locale = val
      setCookie('lang', val)
    }
  },
  mounted () {
    bus.$on('openLogin', this.openLogin)
    let email = sessionStorage.getItem('Email')
    if (email) {
      this.$store.commit(Mutations.setUserName, email)
      this.$store.commit(Mutations.setLogin, false)
    }
    // 多语言
    const lang = getCookie('lang')
    if (lang)  {
      console.log('GetCookie Successfully! lang: ', lang)
      this.defaultLanguage = lang
      this.$i18n.locale = lang
    } else {
      console.log('GetCookie Failed! default lang: en')
      this.$i18n.locale = this.defaultLanguage
    }
    // end
  }
}
</script>

<style scoped lang="scss">
  @import "@/assets/scss/module/module-header.scss";
</style>