<template>
  <modal class="loginregister-modal" name="LoginRegister" :width="modalWidth" :height="modalHeight" :clickToClose="false">
    <el-container class="fullContent">
      <el-aside class="fullContent LoginRegisterAsidePhone">
        <div class="image_ai">
          <span>
            {{$t('Hello')}} ~
          </span>
          <p v-if="!loginshow">
            {{$t('New to AVCLabs AI ? Create your AVCLabs account to access free credits and save image without watermark.')}}
          </p>
          <p v-else>
            {{$t('Login with your AVCLabs AI account Access free credits and save image without watermark.')}}
          </p>
        </div>
      </el-aside>
      <el-main class="fullContent">
        <div class="flexRowEnd img_del">
          <img :src="$Image('close.png')" class="thumbnail" @click="onClose" />
        </div>
        <!-- 注册 -->
        <div class="flexColCenter" v-if="!loginshow">
          <div class="register_account" v-if="registerJump">
            <h3>{{$t('Create an Account')}}</h3>
          </div>
          <div class="signUp-box login-box flexColCenter" v-if="registerJump">
            <div style="margin-top:32px; width:100%;" class="flexColCenter">
              <div class="loginInputBox">
                <el-input class="login-input flex-row-center" :placeholder="$t('Email')" v-model="account" tabindex="1" maxlength="128"></el-input>
                <img class="email" :src="$Image('ico-email.png')" alt="">
              </div>
              <div class="loginInputBox">
                <el-input class="login-input flex-row-center" style="margin-top:20px;" :placeholder="$t('Password')" :type="isShowPassword ? 'text' : 'password'" v-model="password" tabindex="2" maxlength="16" minlength="8"></el-input>
                  <img class="password" :src="$Image('ico-password.png')" alt="">
                  <img class="hide1" v-if="!isShowPassword" @click="onShowPassword" :src="$Image('ico-hide.png')" alt="">
                  <img class="hide2" v-if="isShowPassword" @click="onShowPassword" :src="$Image('ico-display.png')" alt="">
              </div>
              <div class="loginInputBox">
                <el-input class="login-input flex-row-center" style="margin-top:20px;" :placeholder="$t('Repeat Password')" :type="isShowRepeatPassword ? 'text' : 'password'" v-model="repeatPassword" tabindex="3" maxlength="16" minlength="8"></el-input>
                <img class="password" :src="$Image('ico-password.png')" alt="">
                <img class="hide1" v-if="!isShowRepeatPassword" @click="onShowRepeatPassword" :src="$Image('ico-hide.png')" alt="">
                <img class="hide2" v-if="isShowRepeatPassword" @click="onShowRepeatPassword" :src="$Image('ico-display.png')" alt="">

                <div class="signup-mobile-text">
                  <el-checkbox v-model="radio"></el-checkbox>
                  <span style="padding: 0 8px;">{{$t('By clicjing on “Sign up”, you are agreeing to our Terms')}}</span>
                  <span class="toSingnUp" @click="onTermsOfUse">{{$t('View Terms')}}</span>
                </div>

              </div>
              <div class="error_type" v-if="error">
                {{errorType}}
              </div>
              <div class="errorStyle" v-if="!error && onClickSignUp">
                <i class="el-icon-loading"></i>
              </div>
              <el-button class="btn-process" @click="onSignup" style="margin-top:20px;">
                {{ $t('Sign up') }}
              </el-button>
              <label style="margin-top:14px;color:#333;font-size:12px;">
                {{$t('Alredy have an account ?')}}
                <span class="toSingnUp" @click="goLogin">{{$t('Login')}}</span>
              </label>
            </div>
          </div>
          <!-- 注册成功跳转邮箱 -->
          <div class="Register_success flexColCenter" v-else>
            <h5>{{$t('Registraton successful')}}</h5>
            <p>{{$t('We will send the activation mail to your mailbox ,Please click the link in the ail to complete the last registration.')}}</p>
            <label class="success_label">
                {{$t('Didn’t receive the email ?')}}
              <span class="toSingnUp" @click="sendEmail" v-if="sendMail">{{$t('Send another email')}}</span>
              <span class="toSingnUp_wait" v-else>{{$t('Email has been sent.')}} ({{waitTime}})</span>
            </label>
            <label class="success_labels">
              {{$t('Have you completed all steps ?')}}
              <span class="toSingnUp" @click="goLogin">{{$t('Login')}}</span>
            </label>
          </div>
          <!-- 注册成功跳转邮箱 end-->
        </div>
        <!-- 注册 end-->

        <!-- 登录 -->
        <div class="flexColCenter" v-else>
          <div class="register_account" style="padding-top: 38px;" v-if="forgotPasswordShow">
            <h3>{{$t('Login')}}</h3>
          </div>
          <div class="signUp-box login-box flexColCenter" v-if="forgotPasswordShow">
            <div style="margin-top:32px; width:100%;" class="flexColCenter">
              <div class="loginInputBox">
                <el-input class="login-input flex-row-center" :placeholder="$t('Email')" v-model="account" tabindex="1" maxlength="128">
                </el-input>
                <img class="email" :src="$Image('ico-email.png')" alt="">
              </div>
              <div class="loginInputBox">
                <el-input class="login-input flex-row-center" style="margin-top:20px;" :placeholder="$t('Password')" :type="isShowPassword ? 'text' : 'password'" v-model="password" tabindex="2" maxlength="16" minlength="8"></el-input>
                  <img class="password" :src="$Image('ico-password.png')" alt="">
                  <img class="hide1" v-if="!isShowPassword" @click="onShowPassword" :src="$Image('ico-hide.png')" alt="">
                  <img class="hide2" v-if="isShowPassword" @click="onShowPassword" :src="$Image('ico-display.png')" alt="">
              </div>
              <div class="error_type" v-show="error">
                {{errorType}}
              </div>
              <label class="error_email_sent" v-show="isNotActivated && error">
                {{$t('Current account is not activated.')}}
                <span class="toSingnUp" @click="sendEmail" v-if="sendMail">{{$t('Send another email')}}</span>
                <span class="toSingnUp_wait" v-else>{{$t('Email has been sent.')}} ({{waitTime}})</span>
              </label>
              <div class="errorStyle" v-show="!error && onClickLongin">
                <i class="el-icon-loading"></i>
              </div>
              <el-button class="btn-process" :disabled="isDisableLogin&&!error" @click="onLogin" style="margin-top:38px;">
                {{ $t('Login') }}
              </el-button>
              <label class="loginInputBox flexRowBetween" style="margin-top:14px;color:#333;font-size:12px;">
                <span class="to_SingnUp" @click="goRegister">{{$t('Sign up')}}</span>
                <span class="toSingnUp" @click="goForgot">{{$t('Forgot Password?')}}</span>
              </label>
              <div class="Google_login flexColCenter">
                <div class="flex-row-center Google_login_text">
                  <!-- <span>————————</span> -->
                  <p>{{$t('Or sign in with')}}</p>
                  <!-- <span>————————</span> -->
                </div>

                <div class="flex-row-center">
                <a :href="config.BASE_URL+'Auth/Google'">
                  <img :src="$Image('login-logo-google.png')" alt="google login">
                </a>
                <a :href="config.BASE_URL+'Auth/FaceBook'" >
                  <img :src="$Image('facebook_login.png')" alt="facebook login" style="width: 28px;heigth: 28px;margin-left: 30px;">
                </a>
                </div>

              </div>
            </div>
    
          </div>
          <!-- Forgot Password -->
          <div v-else class="register_account" style="padding-top: 30px;">
            <div class="flexColCenter">
              <h3>{{$t('Forgot Password?')}}</h3>
              <p class="forgot_p" v-if="!sentPasswordEmail">&ensp;&ensp;&ensp;{{$t('Please enter the email address you used to log into AVCLabs.com. After you submit the request, we will send you a password reset link.')}}</p>
              <p class="forgot_p" v-else style="text-align: center;">&ensp;&ensp;&ensp;{{$t('We just sent an email with your password to the email address you entered. Please check your inbox to find dinstructions. You may need to check your Spam or Junk folder as well.')}}</p>
            </div>
            <div class="signUp-box login-box flexColCenter" v-if="!sentPasswordEmail">
              <div style="margin-top:32px; width:100%;" class="flexColCenter">
                <div class="loginInputBox">
                  <el-input class="login-input flex-row-center" :placeholder="$t('Email')" v-model="account" tabindex="1" maxlength="128"></el-input>
                  <img class="email" :src="$Image('ico-email.png')" alt="">
                </div>
                <div class="error_type" v-show="error">
                  {{errorType}}
                </div>
                <div class="errorStyle" v-show="!error && onClickSubmit">
                  <i class="el-icon-loading"></i>
                </div>
                <el-button class="btn-process" :disabled="isDisableLogin&&!error" @click="onSubmit" style="margin-top:38px;">
                  {{ $t('Submit') }}
                </el-button>
              </div>
            </div>
            <label class="flexRowCenter" style="margin-top:14px;color:#333;font-size:12px;">
              <span class="toSingnUp" @click="goLogin">{{$t('Return to sign in')}}</span>
            </label>
          </div>
          <!-- Forgot Password END-->
        </div>
        <!-- 登录 end-->

      </el-main>
    </el-container>
  </modal>
</template>

<script>
import JSEncrypt from 'jsencrypt'
import * as Mutations from '@/store/mutations.js'
import { mapState } from 'vuex'
import bus from '@/assets/js/bus'
import { userRegister, sendEmailReactive, ResetPassword, Login, getModelList, getLoginStamp, getCosToken, googleLogin } from '@/assets/js/request/api'
export default {
  name: 'LoginRegister',
  data() {
    return {
      modalWidth: '48%',
      modalHeight: '520px',
      account: '',
      password: '',
      repeatPassword: '',
      isShowPassword: false,
      isShowRepeatPassword: false,
      radio: false,
      error: false,
      errorType: '',
      onClickSignUp: false,
      loginshow: true,
      isDisableLogin: false,
      onClickLongin: false,
      registerJump: true,
      sendMail: true,
      waitTime: 60,
      isNotActivated: false,
      forgotPasswordShow: true,
      onClickSubmit: false,
      sentPasswordEmail: false,
      emailError: false,
      googleObj: {}
    }
  },
  methods: {
    async onGoogle () {
      try {
        let a = location.href
        console.log('aaaaaa', a)
      } catch (error) {
        console.log('error', error)
      }
    },
    async sendEmail () {
      this.sendMail = false
      try {
        let parmas = {
          username: this.account
        }
        let result = await sendEmailReactive(parmas)
        console.log('Reactive', result)
        if (result) {
          this.waitTime = 60
          var timer = setInterval(() => {
            this.waitTime -= 1
            if (this.waitTime === 0) {
              this.waitTime = 0
            }
          }, 1000)
          setTimeout(() => {
            clearInterval(timer)
            this.sendMail = true
          }, 60000)
        }
      } catch (error) {
        return ''
      }
    },
    goRegister () {
      this.loginshow = false
      this.registerJump = true
      this.error = false
      this.isDisableLogin = false
    },
    onTermsOfUse () {
      window.open('https://www.avclabs.com/terms-of-use.html', '_blank')
    },
    goLogin () {
      this.loginshow = true
      this.forgotPasswordShow = true
      this.error = false
      this.isDisableLogin = false
      this.account = ''
      this.password = ''
      this.repeatPassword = ''
      this.onClickLongin = false
    },
    goForgot () {
      this.account = ''
      this.error = false
      this.forgotPasswordShow = false
      this.sentPasswordEmail = false
      this.isDisableLogin = false
    },
    onResultSuccessClose () {
      this.error = false
      this.isDisableLogin = false
      this.loginshow = true
      this.registerJump = true
      this.forgotPasswordShow = true
      this.$modal.hide('LoginRegister')
    },
    onClose () {
      this.error = false
      this.isDisableLogin = false
      this.loginshow = true
      this.registerJump = true
      this.forgotPasswordShow = true
      // setTimeout(() => {
      //   bus.$emit('initWebClose')
      // }, 800)
      if (this.AnonymousLogin === true) {
        bus.$emit('onCloseLogin')
      }
      this.$modal.hide('LoginRegister')
    },
    // 邮箱验证
     onRegisterUsername () {
       console.log('333333333333')
      let Reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
      if (this.account === '') {
        this.emailError = true
        this.error = true
        this.errorType = this.$t('Email cannot be blank.')
      } else if (!Reg.test(this.account.trim())) {
        this.emailError = true
        this.error = true
        this.errorType = this.$t('Please enter a valid email address')
      } else {
        this.emailError = false
        this.error = false
      }
    },
    // 密码验证
    onRegisterPassword () {
      console.log('22222222222')
      this.onRegisterUsername()
      if (this.password === '') {
        if (!this.error) {
          this.error = true
          this.errorType = this.$t('Password cannot be blank.')
        }
      } else {
        // if (!this.error) {
        //   this.error = false
        // }
        if (!this.error && this.password.length >= 8 && this.password.length <= 16) {
          this.error = false
        } else {
          if (!this.emailError) {
            this.isNotActivated = false
            this.error = true
            this.errorType = this.$t('Please enter a password of 8 to 16 characters.')
          }
        }
      }
    },
    // 密码邮箱提示
    onconfirm () {
      console.log('111111111111')
      this.onRegisterPassword()
      if (this.repeatPassword === '') {
        if (!this.error) {
          this.error = true
          this.errorType = this.$t('Repeat Password cannot be blank.')
        }
      } else if (this.repeatPassword !== this.password) {
        if (!this.error) {
          this.error = true
          this.errorType = this.$t('The two passwords you entered do not match.')
        }
      } else {
        if (!this.error) {
          this.error = false
        }
      }
    },
    // 用户注册
    async onSignup () {
      if (!this.radio) {
        this.error = true
        this.errorType = this.$t('Please read and agree to the terms of service first.')
        return
      }
      this.onconfirm()
      this.onClickSignUp = true
      if (!this.error) {
        const stamp = await getLoginStamp()
        console.log(stamp)
        const encrypt = new JSEncrypt()
        encrypt.setPublicKey(`-----BEGIN PUBLIC KEY-----
  MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC51ywBVBF899Rt6brFw1x4jM8b
  /VzqEq+sqK2m1CYPZJEJuHvJWeNjksZLSf9olOU1cu48dIdVNHUGa0vVZfTggP0d
  GeCtKDbLiwzM0jEBy7kbPhDT1wCB/nZCY1hxWBhd7o3vnJX8pGsEvytaw+nSVkLm
  xHsnQpbgspUG8cMEmwIDAQAB
  -----END PUBLIC KEY-----`)
        const encryptedpwd = encrypt.encrypt(stamp + this.password)
        let parmas = {
          uid: this.userId ? this.userId : '',
          username: this.account,
          password: encryptedpwd,
          email: this.account
        }
        const result = await userRegister(parmas)
        switch (result.code) {
          case 0:
            this.registerJump = false
            break
          case -11:
            this.error = true
            this.errorType = this.$t('Username does not exist.')
            break
          case -12:
            this.error = true
            this.errorType = this.$t('Password wrong.')
            break
          case -13:
            this.error = true
            this.errorType = this.$t('User is existed')
            break
          case -14:
            this.error = true
            this.errorType = this.$t('User not actived.')
            break
          case -15:
            this.error = true
            this.errorType = this.$t('UID is null')
            break
          case -16:
            this.error = true
            this.errorType = this.$t('Username is null.')
            break
          case -17:
            this.error = true
            this.errorType = this.$t('User has been registed.')
            break
          default:
            this.error = true
            this.errorType = this.$t('unexpected error.')
        }
        this.onClickSignUp = false
        console.log(result)
      } else {
        this.onClickSignUp = false
      }
    },
    async loginResult (result) {
      let { UID, Name, VideoCredit, ImageCredit, UploadCredit, SubscriptCredit } = result.data
      console.log(`Login Success, sid: ${UID}, email: ${Name}, video_credit: ${VideoCredit}, image_credit: ${ImageCredit}, upload_credit: ${UploadCredit}, SubscriptCredit: ${SubscriptCredit}`)
      sessionStorage.setItem('Sid', UID)
      sessionStorage.setItem('Email', Name)
      this.$store.commit(Mutations.setLogin, false)
      this.$store.commit(Mutations.setUserName, Name)
      this.$store.commit(Mutations.setUserAnonymous, false)
      this.$store.commit(Mutations.setUserId, UID)
      // 获取COS凭证
      const Token = await getCosToken({sid: UID}, this)
      console.log('GetSTS Success, STS: ', Token)
      this.$store.commit(Mutations.setCosToken, Token)
      const credits = {
        VideoCredit: VideoCredit,
        ImageCredit: ImageCredit,
        UploadCredit: UploadCredit,
        SubscriptCredit: SubscriptCredit
      }
      sessionStorage.setItem('Credits', JSON.stringify(credits))
      this.$store.commit(Mutations.setCredits, credits)
      // 获取modelList
      const { data } = await getModelList({sid: UID})
  
      let modeData = data.slice(3, 8)
      this.$store.commit(Mutations.setModelList, modeData)
      console.log('GetModelList Success, modelList: ', modeData)

      this.onResultSuccessClose()
      this.$message({
        type: 'success',
        message: this.$t('You have successfully logged in.')
      })
      this.$emit('loginSuccess', true)
   
    },
    // 登录
    async onLogin () {
      this.onRegisterPassword()

      if (!this.error) {
        // sessionStorage.setItem('localToken', new Date().valueOf() + 1800000)
        const stamp = await getLoginStamp().catch(() => {
          this.error = true
          this.errorType = this.$t('message.networkDelay')
          this.onClickLongin = false
          this.isDisableLogin = false
        })
        this.isDisableLogin = true
        this.onClickLongin = true
        console.log('stamp', stamp)
        const encrypt = new JSEncrypt()
        encrypt.setPublicKey(`-----BEGIN PUBLIC KEY-----
  MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC51ywBVBF899Rt6brFw1x4jM8b
  /VzqEq+sqK2m1CYPZJEJuHvJWeNjksZLSf9olOU1cu48dIdVNHUGa0vVZfTggP0d
  GeCtKDbLiwzM0jEBy7kbPhDT1wCB/nZCY1hxWBhd7o3vnJX8pGsEvytaw+nSVkLm
  xHsnQpbgspUG8cMEmwIDAQAB
  -----END PUBLIC KEY-----`)
        const encrypted = encrypt.encrypt(stamp + this.password)
        console.log('encrypted', encrypted)
        try {
          let parmas = {
            username: this.account,
            password: encrypted
          }
          const result = await Login(parmas)
          console.log('result', result)
          switch (result.code) {
            case 0:
              this.loginResult(result)
              break
            case -11:
              this.error = true
              this.errorType = this.$t('Username does not exist.')
              break
            case -12:
              this.error = true
              this.errorType = this.$t('Password wrong.')
              break
            case -13:
              this.error = true
              this.errorType = this.$t('User is existed')
              break
            case -14:
              this.errorType = ''
              this.error = true
              this.isNotActivated = true
              break
            case -15:
              this.error = true
              this.errorType = this.$t('UID is null')
              break
            case -16:
              this.error = true
              this.errorType = this.$t('Username is null.')
              break
            default:
              this.error = true
              this.errorType = this.$t('unexpected error.')
          }
          this.onClickLongin = false
          this.isDisableLogin = false
        } catch (error) {
          this.error = true
          this.errorType = this.$t('Network delay, please try again.')
          this.isDisableLogin = false
          this.onClickLongin = false
        }
      }
    },
    // 重置密码
    async onSubmit () {
      this.onRegisterUsername()
      if (!this.error) {
        const result = await ResetPassword({username: this.account})
        console.log(result)
        switch (result.code) {
          case 0:
            this.sentPasswordEmail = true
            break
          case -11:
            this.error = true
            this.errorType = this.$t('Username does not exist.')
            break
          case -12:
            this.error = true
            this.errorType = this.$t('Password wrong.')
            break
          case -13:
            this.error = true
            this.errorType = this.$t('User is existed')
            break
          case -14:
            this.error = true
            this.errorType = this.$t('User not actived.')
            break
          case -15:
            this.error = true
            this.errorType = this.$t('UID is null')
            break
          case -16:
            this.error = true
            this.errorType = this.$t('Username is null.')
            break
          default:
            this.error = true
            this.errorType = this.$t('unexpected error.')
        }
      }
    },
    onShowPassword () {
      this.isShowPassword = !this.isShowPassword
    },
    onShowRepeatPassword () {
      this.isShowRepeatPassword = !this.isShowRepeatPassword
    },
    onCloseLoginRegister () {
      this.$modal.hide('LoginRegister')
    }
  },
  computed: {
    ...mapState(['userId', 'config', 'AnonymousLogin'])
  },
  watch: {
    loginShow () {
      this.loginshow = true
    }
  },
  async mounted () {
    bus.$on('GoogleData', async (info) => {
       const opData = {
        user: info.user,
        from: info.from,
        code: info.code
      }
      let getData = await googleLogin(opData)
      this.loginResult(getData)
    })
    bus.$on('onCloseLoginRegister', this.onCloseLoginRegister)
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/module/module-login.scss';
</style>
