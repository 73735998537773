<template>
  <el-container class="home fullContent" v-loading.fullscreen="handleLoadingShow" element-loading-custom-class="handle-loading">
    <el-main class="home-main">
      <div class="home-container" style="overflow-y: scroll !important;">
        <UploadView v-show="!hasImage" @syncUpload="handleUpload" @changeHandleLoadingShow=changeHandleLoadingShow 
        ref="upload">
        </UploadView>
        <ProcessListView v-show="hasImage" :currentPhotoList="currentPhotoList" 
        @deleteImages="deleteImages" 
        @checkedPhotoChanged="checkedPhotoChanged" 
        :btnDisabled="isUploading || isEnhancing" 
        @onEnhance="onEnhance"
        ref="processList"></ProcessListView>
        <!-- <HistoryListView v-show="tabName === 'history'" :currentPhotoList="currentPhotoList" ref="historyList"></HistoryListView> -->
      </div>
    </el-main>
  </el-container>
</template>

<script>
import { mapState } from 'vuex'
import bus from '@/assets/js/bus'
import * as Mutations from '@/store/mutations.js'
import ProcessListView from './common/ProcessListView.vue'
import UploadView from './UploadView.vue'
import { deleteImages, getImages, getReqCredits } from '@/assets/js/request/api'

export default {
  name: 'Home',
  data() {
    return {
      tabName: 'process', // 'process' 'history'
      // 未被追踪的完成的数量
      unDetectedProcessedNum: 0,
      // 传入process/history的图片列表
      currentPhotoList: [],
      // 上传图片之后, 默认选中, 即上传之后需要将id添加至checkedPhotoList
      checkedPhotoList: [],
      checkedHistoryList: [],
      // 每次获取正在增强的图片进度 存入data中
      processStatus: [],
      remainMessageShow: false,
      handleLoadingShow: false,
      newArr: []
    }
  },
  components: {
    ProcessListView,
    UploadView
  },
  methods: {
    deleteImages(images, msg, isAll = false) {
      // console.log('deleteImages: ', images)
      const ids = images.map(image => image.id)
      this.$confirm(msg, {
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: this.$t('confirm'),
        iconClass: this.$t('confirm-icon')
      }).then(async () => {
        this.handleLoadingShow = true
        const queryString = {
          sid: this.userId
        }
        const params = isAll ? { deleteAll: true } : { deleteAll: false, images: ids}
        // console.log('deleteImagesParams: ', queryString, params)
        const deleteResult = await deleteImages({ queryString, params })
        console.log('deleteResult: ', deleteResult)
        if (deleteResult.data.SubscriptCredit) {
          const { ImageCredit, UploadCredit, VideoCredit, SubscriptCredit } = deleteResult.data
          const credits = {
            VideoCredit,
            ImageCredit,
            UploadCredit,
            SubscriptCredit
          }
          // console.log('setCredits', credits)
          sessionStorage.setItem('Credits', JSON.stringify(credits))
          this.$store.commit(Mutations.setCredits, credits)
        }
        this.handleLoadingShow = false

        if (deleteResult.code === 0) {  
          this.$message({
            type: 'success',
            message: this.$t('Deleted successfully')
          })
          this.currentPhotoList = this.currentPhotoList.filter(item => ids.indexOf(item.id) === -1)
          console.log(this.currentPhotoList)
          // 删除之后同步checkedPhotoList列表
          this.$refs.processList.modifyCheckedPhotoList(this.checkedPhotoList.filter(checkedPhoto => images.every(image => image.id !== checkedPhoto.id)))
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('Delete cancel')
        })      
      })
    },
    // 处理UploadView中发送的上传事件
    async handleUpload(status, images) {
      console.log('call handleUpload', status, images)
      switch (status) {
        // 图片开始上传, status: 'upload', 
        // images: [{
        //   id: file.id,
        //   thumb: replaceSrc,
        //   name: file.name,
        //   width: imageObj.width,
        //   height: imageObj.height,
        //   status: 'upload'
        // }, ...]
        case 'upload':
          this.currentPhotoList.unshift(...images)
          break
        // 图片上传中, status: 'uploading'
        // images: [{
        //   id: '111',
        //   progress: '20'
        // }]
        case 'uploading':
          images.forEach(image => {
            const item = this.currentPhotoList.find(item => item.id === image.id)
            item.progress = image.progress
          })
          break
        // 图片上传完成, status: 'ready'
        // images: ['gxg', 'bkb']
        case 'ready':
          images.forEach(id => {
            const item = this.currentPhotoList.find(item => item.id === id)
            item.status = status
            this.checkedPhotoList.push(item)
            this.$refs.processList.modifyCheckedPhotoList(this.checkedPhotoList)
          })
          break
        // 图片上传失败, status: 'failure'
        // images: ['gxg', 'bkb']
        case 'failure':
          images.forEach(id => {
            const item = this.currentPhotoList.find(item => item.id === id)
            item.status = status
          })
          break

        default:
          break
      }
    },
    checkedPhotoChanged(newList) {
      this.checkedPhotoList = newList
    },
    checkedHistoryChanged(newList) {
      this.checkedHistoryList = newList
    },
    async onEnhance() {
      // console.log('onEnhance==>:', this.hasImage)
      if (this.hasImage) {
        const checkedIds = this.checkedPhotoList.map(photo => photo.id)
        const restImageCredit = this.credits?.SubscriptCredit
        const checkedIdsLength = checkedIds.length
        var reqCredits = checkedIds.length

        // Try to get requied credits count
        try {
          let data = {
            queryString: {
              sid: this.userId
            },
            params: {
              images: checkedIds,
              model: this.currentModelInfo
            }
          }
          console.log('Enhance data', data)
          const creditsResult  = await getReqCredits(data)
          const reqCreditsData = creditsResult.data
          console.log('Required credits: ', reqCreditsData.credits)
          const { ImageCredit, UploadCredit, VideoCredit, SubscriptCredit } = reqCreditsData.credits
          const credits = {
            VideoCredit,
            ImageCredit,
            UploadCredit,
            SubscriptCredit
          }
          console.log('setCredits', credits)
          sessionStorage.setItem('Credits', JSON.stringify(credits))
          this.$store.commit(Mutations.setCredits, credits)
          reqCredits = reqCreditsData.total
        } catch (error) {
          console.log(error)
        }

        if (this.AnonymousLogin === true) {
          const msg = this.$t('You should be autheticated to perform this action')
          this.$confirm(msg, {
            confirmButtonText: this.$t('Ok'),
            showCancelButton: false,
            customClass: 'confirm',
            showClose: false,
            iconClass: 'confirm-icon',
            dangerouslyUseHTMLString: true,
            type: 'warning'
          })
          return false
        }

        if (restImageCredit < reqCredits) {
          const msg = `${this.$t('You have selected {checkedIdsLength} photos and need {reqCredits} credits, your credits are insufficient, check our pricing plan for more credits.', { checkedIdsLength: checkedIdsLength, reqCredits: reqCredits })} <a href="https://www.avclabs.com/price-photo-online.html" style="color: #409eff;" target="_blank">${this.$t('Check Plans')}</a>`

          this.$confirm(msg, {
            confirmButtonText: this.$t('Ok'),
            showCancelButton: false,
            customClass: 'confirm',
            showClose: false,
            iconClass: 'confirm-icon',
            dangerouslyUseHTMLString: true,
            type: 'warning'
          })
          return false
        }
        const enhanceComfirm = this.$t('You have selected {checkedIdsLength} images and need {reqCredits} credits, are you sure you want to start enhancing?', { checkedIdsLength: checkedIdsLength, reqCredits: reqCredits })
        const result = await this.$confirm(enhanceComfirm, {
          confirmButtonText: this.$t('Yes'),
          cancelButtonText: this.$t('No'),
          customClass: 'confirm',
          showClose: false,
          type: 'warning',
          iconClass: 'confirm-icon'
        }).catch(() => {})
        if (!result) return

        // this.handleLoadingShow = true
        const queryParam = this.checkedPhotoList
        queryParam.map(item => {
          item.label = this.currentModelInfo.label
          item.status = 'waiting'
        })
        localStorage.setItem('queryParam',JSON.stringify(queryParam))

        let data = {
          queryString: {
            sid: this.userId
          },
          params: {
            images: checkedIds,
            model: this.currentModelInfo
          }
        }
         this.$nextTick(function () {
           bus.$emit('onEnhance', data)
         })
        this.$router.push({path:'/HistoryListView'})

      }
    },
    async getCurrentPhotoList() {
        this.$emit('toggleLoading', true)
        try {
        if (!this.userId) return []
        const imagesResult = await getImages({ sid: this.userId })
        console.log('GetImages Success, processPhotoList: ', imagesResult)
        if (imagesResult.code === -9) {
          sessionStorage.removeItem('Email')
          sessionStorage.removeItem('Sid')
          sessionStorage.removeItem('Credits')
          this.$emit('toggleLoading', false)
          return []
        }
        const images = imagesResult?.data?.images
        // const processSet = ['progress']
        // const processSet = ['waiting', 'active', 'progress']

        const newCheckedPhotoList = images.filter(image => {
          return this.checkedPhotoList.find(checkedPhoto => checkedPhoto.id === image.id)
        })
        this.$refs.processList.modifyCheckedPhotoList(newCheckedPhotoList)
        images.forEach(image => {
          image.image = this.config?.BASE_URL + image.image
          image.thumb = this.config?.BASE_URL + image.thumb
        })

        return images
      } catch (error) {
        console.log(error)
        sessionStorage.removeItem('Email')
        sessionStorage.removeItem('Sid')
        sessionStorage.removeItem('Credits')
        this.$emit('toggleLoading', false)
        return []
      }
    },
    formatSecond(second) {
      const result = parseInt(second)

      let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600)
      let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60))
      let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60))
  
      return `${h}:${m}:${s}`
    },
    changeHandleLoadingShow(val) {
      this.handleLoadingShow = val
    },
    async getCurrentPhoto () {
      this.currentPhotoList = await this.getCurrentPhotoList()
      this.$emit('toggleLoading', false)
    },
    async tabNames() {
      // console.log('tabNames', nv)
      this.currentPhotoList = await this.getCurrentPhotoList()
      // console.log(this.currentPhotoList, '222222222')
      this.$refs.processList.modifyIsIndeterminate(true)
      // this.$refs.historyList.modifyIsIndeterminate(true)
      this.$emit('toggleLoading', false)
    }
  },
  computed: {
    ...mapState(['userId', 'currentModelInfo', 'config', 'credits', 'isEnhancing', "AnonymousLogin"]),
    // isUploading:是否在上传中
    // 此时禁用Start to Process按钮
    // 当全部上传完成的时候 isUploading变为false.  Start to Process可以点击
    isUploading() {
      return this.currentPhotoList.some(item => item.status === 'upload')
    },
    iconClass() {
      return this.unDetectedProcessedNum === 0 ? 'icon hide' : 'icon'
    },
    hasImage() {
      return this.currentPhotoList.length
    },
    queueLength() {
      return this.processStatus.filter(item => item.status === 'waiting' || item.status === 'active').length
    },
    totalTime() {
      const second = this.processStatus.reduce((cur, next) => {
        cur = cur + next.schedule
        return cur
      }, 0)
      const seconeStr = this.formatSecond(second)

      return seconeStr
    },
    isTimeOver() {
      return this.totalTime === '00:00:00'
    },
    remainMessage() {
      return this.$t(`{queueLength} job is in the queue, {totalTime} estimated remaining. We support offline processing, you can leave this page and the enhanced photos will be added to the completed list.`, { queueLength: this.queueLength, totalTime: this.totalTime })
    }
  },
  created() {
    this.getCurrentPhoto()
  },
  async mounted() {
    bus.$on('tabName', this.tabNames)
  },
  watch: {
    async tabName() {
      this.currentPhotoList = await this.getCurrentPhotoList()
      // this.$refs.processList.modifyIsIndeterminate(true)
      // this.$refs.historyList.modifyIsIndeterminate(true)
      // this.$emit('toggleLoading', false)

      // if (nv === 'history') {
      //   this.unDetectedProcessedNum = 0
      //   this.refreshIcon()
      // }
    }
  }
}
</script>
