<template>
<div class="fullContent" style="position: relative;height:100%;">
  <div class="upscalling_main">

    <div class="upload-view upload_bgc flexColCenter">

      <div class="flexRowCenter mobile_col">
        <div class="flexColStart photo_title">
          <div class="photo_title_g" style="padding: 58px 0 23px;">
            {{ $t('Upscale an Image by 400% without Losing Quality') }}
            
          </div>
          <div class="photo_title_t">
            {{ $t('Online Al Image Upscale: Upscale and enlarge the image size to 200%, 300%,400% and enhance the image quality by using Al technology.It supportsautomatically removing the noise/grain from the image,and adjusting brightness, situation,contrast,no tedious editing to adjust complex curves and levels.') }}
            
          </div>
        </div>
        <img :src="$Image('upscalling_top_banner.png')" alt="">
      </div>
      
      <div class="flexColCenter photo_upload " ref='select_frame'  ondragstart="return false">
        <p class="photo_upload_d">
          {{ $t('Drag image here or click to upload.') }}
          </p>
        <div class="upload_drag flexRowCenter">
          <div class="config_upload flexRowStart">
            <div class="click_upload flexRowCenter" @click="onChoose">
              <img :src="$Image('upload_image.png')" alt="">
              <span>
                {{ $t('Upload Image') }}
              </span>
            </div>
          </div>
        </div>
        <p class="photo_upload_l" @click="onDownloadMore">
          {{ $t('Download App to unlock more features') }}  ></p>
      </div>

    </div>
  
    <!-- img5 -->
    <div class="flexColCenter upload-view form_image">
      <div class="form_image_u">
        {{ $t('Online Free AI Image Upscaler and Quality Enhancer') }}
        
      </div>
      <p class="form_image_y">
        {{ $t("An online AI photo upscaling and enlarging tool that works on upscaling photos by 1x, 2x, and 4x, to enlarge photo resolution and enhance its quality, producing the clearest visual effect, without losing details.") }}
      </p>

      <CustomizeImg :imgUrl='upscalling1' :enhancerUrl='upscalling2' :handlelefts="handleleft" :parentClientWidths="parentClientWidth"></CustomizeImg>

      <div class="flexRowCenter mar_t">
        <div class="config_upload flexRowStart" @click="onTryFree">
          <div class="click_upload click_btn_w flexRowCenter">
            <span>
              {{ $t('Try it for free') }}
            </span>
          </div>
        </div>
        <div class="google_play_btn flexRowStart">
          <div class="click_google_play download_w flexRowCenter" @click="onDownload">
            <div class="download_icon"></div>
            <span>
              {{ $t('Download for Win&Mac') }}
            </span>
          </div>
        </div>
      </div>

    </div>

    <!-- carousel img -->
    <div class="flexColCenter upload-view form_image last_mb">
      <div class="form_image_u">
        {{ $t('What Kind Of Images Can Be Al Upscsled') }}
       
      </div>
      <p class="form_image_y">
        {{ $t("See some samples to check the original and Al upscaled images simply. The Al could produce a great result even though the original image is of bad quality.") }}
    
      </p>
      <div class="anime-images">
        <!-- {{ $t('Anime Images') }} -->
      </div>
    <div class="fullContent">
      <el-carousel height="600px" indicator-position="outside">
        <el-carousel-item class="el-carousel__item" v-for="(item, index) in carouselImgs" :key="index" >
          <img class="carousel-image" :src="$Image(item.url)"/>
        </el-carousel-item>
      </el-carousel>
    </div>

      <div class="flexRowCenter mar_t">
        <div class="config_upload flexRowStart" @click="onTryFree">
          <div class="click_upload click_btn_w flexRowCenter">
            <span>
              {{ $t('Try it for free') }}
            </span>
          </div>
        </div>
        <div class="google_play_btn flexRowStart">
          <div class="click_google_play download_w flexRowCenter" @click="onDownload">
            <div class="download_icon"></div>
            <span>
              {{ $t('Download for Win&Mac') }}
            </span>
          </div>
        </div>
      </div>


    </div>
  
    <FooterView></FooterView>
  </div>
</div>

</template>


<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NK52HVJ');</script>
<!-- End Google Tag Manager -->

<script>
import CustomizeImg from '../common/CustomizeImg.vue'
import FooterView from '../common/Footer.vue'
import bus from '@/assets/js/bus'
import { getCookie } from '@/assets/js/utils/cookie'

export default {
  name: 'Upscalling',
  metaInfo: {
    title: 'AI Image Upscaler Online that Increases Images Resolution and Size without Losing Quality',
    meta: [
      {
        name: 'description',
        content: 'Driven by super-resolution AI technology, this AI Image Upscaler is able to increase image resolution without losing quality. It upscales the low-resolution images by 2X, 3X, and 4X online, enlarging images without losing quality.'
      },
      {
        name: 'keywords',
        content: 'ai image upscale, upscale image online, enlarge images online, increase image size'
      }
    ]
  },
  data() {
    return {
      handleleft: 926/2,
      parentClientWidth: 926,
      upscalling1: 'upscalling1.jpg',
      upscalling2: 'upscalling2.jpg',
      carouselImgs: [
        {url: 'upscalling_anime3.png'},
        {url: 'upscalling_portrait3.png'},
        {url: 'upscalling_printing3.png'},
        {url: 'upscalling_product3.png'},
        {url: 'upscalling_wildlife3.png'}
      ]
    }
  },
  components: {
    CustomizeImg,
    FooterView
  },
  methods: {
    onDownloadMore () {
      const lang = getCookie('lang')
      switch (lang) {
        case 'en':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'de':
          window.open('https://www.avclabs.de/download.html')
          break
        case 'fr':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'es':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'ja':
          window.open('https://www.avclabs.jp/download.html')
          break
        default:
          window.open('https://www.avclabs.com/download.html')
          break
      }
    },
    onChoose () {
      bus.$emit('uploadImage')
      bus.$emit('emitSelect', 'FaceRetouching')
      this.$router.push({path:'/'})
    },
    onTryFree () {
      this.$router.push({path:'/'})
    },
    onDownload () {
      window.location.href = 'https://www.avclabs.com/download.html'
    }
  },

  mounted () {
    this.$refs.select_frame.ondragleave = (e) => {
        e.preventDefault()
    }
    this.$refs.select_frame.ondrop = (e) => {
        e.preventDefault()
        const data = e.dataTransfer.files
        if (data.length < 1) {
            return
        }
        bus.$emit('onGetFile', data)
        this.$router.push({path:'/'})
    }
    this.$refs.select_frame.ondragenter = (e) => {
        e.preventDefault()
    }
    this.$refs.select_frame.ondragover = (e) => {
        e.preventDefault()
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/module/upload-view.scss';
</style>
