<template>
<div class="upload_bg fullContent">

  <div class="upload-view upload_bgc flexColCenter">

    <div class="flexRowCenter  mobile_col">
      <div class="flexColStart">
        <div class="photo_title_u">
          {{$t('Use machine learning to improve and restore photo quality automatically and effortlessly')}}
          </div>
        <div class="photo_title_g">
          {{$t('Get A Better Photo Quality with the Power of AI')}}
          </div>
        <div class="photo_title_t">
          {{$t('Upscale the image resolution from low to high, and enhance quality by denoising, sharpening, face retouching, colorizing, color calibration and more.')}}
           </div>
      </div>
      <img :src="$Image('main_top_banner.png')" alt="">
    </div>
    
    <div class="flexColCenter photo_upload " ref='select_frame'  ondragstart="return false">
      <p class="photo_upload_d">
        {{$t('Drag image here or click to upload.')}}
        </p>
      <div class="upload_drag flexRowCenter">
        <div class="config_upload flexRowStart">
          <div class="click_upload flexRowCenter" @click="onChoose">
            <img :src="$Image('upload_image.png')" alt="">
            <span>
              {{ $t('Upload Image') }}
            </span>
          </div>
        </div>
        <input type="file"
          style="visibility: hidden; width: 0;"
          accept=".png, .jpg, .jpeg, .bmp, .jfif, .webp"
          @change="onGetFile"
          ref="uploadinput"
          id="upload-input"
          multiple="multiple">
      </div>
      <p class="photo_upload_l" @click="onDownloadMore">
        {{$t('Download App to unlock more features')}}  ></p>
    </div>

  </div>
  <!-- video -->
  <div class="flexColCenter index_video">
    <p>
      {{$t('Watch 1-Minute Video to Know How It Works for You')}}
      </p>
    <div class="complete-img complete_img_two">
      <!-- <video loop width="900" height="505" playsinline> -->
      <video loop playsinline>
        <source src="../assets/images/ai-enhanced-video.mp4" data-wf-ignore="true">
      </video>
      <div class="feature_video_play" @click="handleOpenDialog">
        <img src="https://assets-global.website-files.com/6005fac27a49a9cd477afb63/6025ac3ba629110e604db669_Circled%20Play.svg" loading="lazy" width="100" height="100" alt="video play button">
      </div>
    </div>
    <el-dialog width="75%" center :visible.sync='outVisible'>
      <video autoplay loop width="90%" height="680" muted controls>
        <source src="../assets/images/ai-enhanced-video.mp4" data-wf-ignore="true">
      </video>
    </el-dialog>
  </div>
  
  <div class="upload-view upload_bgc flexRowCenter automat_fully">
    <div class="flexColCenter automatically_a">
      <img :src="$Image('fully-automatic.png')" alt="">
      <p class="auto_p">
        {{$t('100% Automatically')}}
        </p>
      <p class="auto_pw">
        {{$t('With the well-trained AI algorithm, you can upscale images online automatically.')}}
        </p>
    </div>
    <div class="flexColCenter automatically_a">
      <img :src="$Image('super-resolution.png')" alt="">
      <p class="auto_p">
        {{$t('Supper Resolution')}}
        </p>
      <p class="auto_pw">
        {{$t('Use Super Resolution technology to upscale image and boost its quality to the max.')}}
        </p>
    </div>
    <div class="flexColCenter automatically_a">
      <img :src="$Image('facial-enhancement.png')" alt="">
      <p class="auto_p">
        {{$t('Facial Recognition & Enhancement')}}
        </p>
      <p class="auto_pw">
        {{$t('The use of deep learning algorithms for facial recognition and enhancement to recover facial details.')}}
        </p>
    </div>
    <div class="flexColCenter automatically_a">
      <img :src="$Image('protect-privacy.png')" alt="">
      <p class="auto_p">
        {{$t('Protect Your Privacy')}}
        </p>
      <p class="auto_pw">
        {{$t('Your uploaded images will be automatcially cleared after 24 hours permanently.')}}
        </p>
    </div>
  </div>

  <!-- img1 -->
  <div class="flexColCenter upload-view form_image">
    <div class="form_image_u">
      {{$t('Upscale Image Resolution from Low to High Online for Free')}}
      
    </div>
    <p class="form_image_y">
      {{$t('You can upscale or resize any kind of image like landscape, wildlife, portrait, anime, wedding, still life, or product, to 2x, 3x, or 4x for purpose of wallpapers, printing, advertisements, and more. No matter what size you are choosing to upscale to, the output quality will never be downgraded. You can upscale any image to 2x for free forever!')}}
    </p>
    <img  class="main_img" :src="$Image('main_upscale_image.jpg')" alt="">
    <div class="flexRowCenter mar_t">
      <div class="config_upload flexRowStart">
        <div class="click_upload click_btn_w flexRowCenter" @click="onChoose">
          <img :src="$Image('upload_image.png')" alt="">
          <span>
            {{ $t('Upload Image') }}
          </span>
        </div>
      </div>

      <div class="google_play_btn flexRowStart" @click="onGooglePlay">
        <div class="click_google_play flexRowCenter">
          <div class="google_play_icon"></div>
          <span>
            {{ $t('Google play') }}
          </span>
        </div>
      </div>

      <div class="google_play_btn flexRowStart">
        <div class="click_google_play download_w flexRowCenter" @click="onDownload">
          <div class="download_icon"></div>
          <span>
            {{ $t('Download for Win&Mac') }}
          </span>
        </div>
      </div>


    </div>
  </div>

  <!-- img2 -->
  <div class="flexColCenter upload-view form_image">
    <div class="form_image_u">
      {{ $t('Remove Noise and Artifacts from Images') }}
    </div>
    <p class="form_image_y">
      {{ $t('It can be difficult to remove noise and artifacts from images, especially if the image is of a low quality. Fortunately, with the help of AI, it is now possible to remove noise and artifacts from images quickly and easily, and the results can be quite impressive. In most cases, all you need to do is upload the image and let the AI tool do its job. The advantage of using AI for denoising photos is that it can be done automatically and can also be applied to large numbers of photos at once. ') }}
    </p>

    <CustomizeImg :imgUrl='noise1' :enhancerUrl='noise2' :handlelefts="handleleft" :parentClientWidths="parentClientWidth"></CustomizeImg>

    <div class="flexRowCenter mar_t">
      <div class="config_upload flexRowStart">
        <div class="click_upload click_btn_w flexRowCenter" @click="onChoose">
          <img :src="$Image('upload_image.png')" alt="">
          <span>
            {{ $t('Upload Image') }}
          </span>
        </div>
      </div>

      <div class="google_play_btn flexRowStart" @click="onGooglePlay">
        <div class="click_google_play flexRowCenter">
          <div class="google_play_icon"></div>
          <span>
            {{ $t('Google play') }}
          </span>
        </div>
      </div>

      <div class="google_play_btn flexRowStart">
        <div class="click_google_play download_w flexRowCenter" @click="onDownload">
          <div class="download_icon"></div>
          <span>
            {{ $t('Download for Win&Mac') }}
          </span>
        </div>
      </div>


    </div>
  </div>
  <!-- img3 -->
  <div class="flexColCenter upload-view form_image">
    <div class="form_image_u">
      {{ $t('AI Face Refinement Tool to Sharpen and Enhance Blurry Faces') }}
      
    </div>
    <p class="form_image_y">
      {{ $t('AI Face Refinement is a new tool that helps to sharpen and enhance blurry faces. The tool uses AI to automatically detect facial features and then applies a series of refinements to make the faces look clearer and sharper. lt is also able to improve portraits by automatically removing blemishes, wrinkles, and dark spots and automatically adjusting the color and brightness.') }}
    </p>

    <CustomizeImg :imgUrl='refinement1' :enhancerUrl='refinement2' :handlelefts="handleleft" :parentClientWidths="parentClientWidth"></CustomizeImg>

    <div class="flexRowCenter mar_t">
      <div class="config_upload flexRowStart">
        <div class="click_upload click_btn_w flexRowCenter" @click="onChoose">
          <img :src="$Image('upload_image.png')" alt="">
          <span>
            {{ $t('Upload Image') }}
          </span>
        </div>
      </div>

      <div class="google_play_btn flexRowStart" @click="onGooglePlay">
        <div class="click_google_play flexRowCenter">
          <div class="google_play_icon"></div>
          <span>
            {{ $t('Google play') }}
          </span>
        </div>
      </div>

      <div class="google_play_btn flexRowStart">
        <div class="click_google_play download_w flexRowCenter" @click="onDownload">
          <div class="download_icon"></div>
          <span>
            {{ $t('Download for Win&Mac') }}
          </span>
        </div>
      </div>


    </div>
  </div>

  <!-- img4 -->
  <div class="flexColCenter upload-view form_image">
    <div class="form_image_u">
      {{ $t('AI Color Calibrator to Correct the Color to Make the Photo More Natural') }}
      
    </div>
    <p class="form_image_y">
      {{ $t('AI Color Calibration is a way to correct the color in your photos to make them look more natural. The approach uses neural networks to analyze the colors in a photo and then applies a series of algorithms to correct the color cast. The calibrated photo looks more like it was taken in natural light, without the blue or orange tint that can often spoil a photo.') }}
    </p>

    <CustomizeImg :imgUrl='calibrator1' :enhancerUrl='calibrator2' :handlelefts="handleleft" :parentClientWidths="parentClientWidth"></CustomizeImg>

    <div class="flexRowCenter mar_t">
      <div class="config_upload flexRowStart">
        <div class="click_upload click_btn_w flexRowCenter" @click="onChoose">
          <img :src="$Image('upload_image.png')" alt="">
          <span>
            {{ $t('Upload Image') }}
          </span>
        </div>
      </div>

      <div class="google_play_btn flexRowStart" @click="onGooglePlay">
        <div class="click_google_play flexRowCenter">
          <div class="google_play_icon"></div>
          <span>
            {{ $t('Google play') }}
          </span>
        </div>
      </div>

      <div class="google_play_btn flexRowStart">
        <div class="click_google_play download_w flexRowCenter" @click="onDownload">
          <div class="download_icon"></div>
          <span>
            {{ $t('Download for Win&Mac') }}
          </span>
        </div>
      </div>


    </div>
  </div>

  <!-- img5 -->
  <div class="flexColCenter upload-view form_image last_mb">
    <div class="form_image_u">
      {{ $t('Quickly and Easily Remove the background from Any Image') }}
      
    </div>
    <p class="form_image_y">
      {{ $t("If you're looking for an easy way to remove image backgrounds, you can use AI to do it. With just one click. you can quickly and easily remove the background from any image, leaving you with a clean, white background that's perfect for use in further post-processing. And best of all, you don't need to waste so much time doing it manually.") }}
    </p>

    <CustomizeImg :imgUrl='background1' :enhancerUrl='background2' :handlelefts="handleleft" :parentClientWidths="parentClientWidth"></CustomizeImg>

    <div class="flexRowCenter mar_t">
      <div class="config_upload flexRowStart">
        <div class="click_upload click_btn_w flexRowCenter" @click="onChoose">
          <img :src="$Image('upload_image.png')" alt="">
          <span>
            {{ $t('Upload Image') }}
          </span>
        </div>
      </div>

      <div class="google_play_btn flexRowStart" @click="onGooglePlay">
        <div class="click_google_play flexRowCenter">
          <div class="google_play_icon"></div>
          <span>
            {{ $t('Google play') }}
          </span>
        </div>
      </div>

      <div class="google_play_btn flexRowStart">
        <div class="click_google_play download_w flexRowCenter" @click="onDownload">
          <div class="download_icon"></div>
          <span>
            {{ $t('Download for Win&Mac') }}
          </span>
        </div>
      </div>


    </div>
  </div>

  <FooterView></FooterView>
</div>
</template>
<script>
import { mapState } from 'vuex'
import { addImage, uploadImage, getCosToken, userLogin, getModelList } from '@/assets/js/request/api'
import CustomizeImg from './common/CustomizeImg.vue'
import FooterView from './common/Footer.vue'
import * as Mutations from '@/store/mutations.js'
import bus from '@/assets/js/bus'
import COS from '../../public/cdn/cos-js-sdk-v5.min.js'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { getCookie } from '@/assets/js/utils/cookie'
export default {
  name: 'Upload',
  data() {
    return {
      checkeds: true,
      checkedCities: [],
      checked: true,
      ShowProcesList: false,
      // imgInfo: {},
      successAddImage: [],
      ImgList: [],
      accessKeyId: null,
      accessKeySecret: null,
      stsToken: null,
      expiration: null,
      AllServernum: null,
      uploadingList: [],
      DmeoVale: [],
      promiseAddAll: [],
      limitMegapixel: [],
      handleleft: 926/2,
      parentClientWidth: 1920,
      noise1: 'main_remove_noise1.jpg',
      noise2: 'main_remove_noise2.jpg',
      refinement1: 'main_ai_face_refinement1.jpg',
      refinement2: 'main_ai_face_refinement2.jpg',
      calibrator1: 'main_color_calibrator1.jpg',
      calibrator2: 'main_color_calibrator2.jpg',
      background1: 'main_remove_background1.jpg',
      background2: 'main_remove_background2.jpg',
      outVisible: false
    }
  },
  components: {
    CustomizeImg,
    FooterView
  },
  computed: {
    ...mapState([
      'userId',
      'imageCredit',
      'processImagesLength',
      'credits',
      'config',
      'cosToken'
    ])
  },
  methods: {
    onGooglePlay () {
      window.open('https://play.google.com/store/apps/details?id=com.avclabs.android')
    },
    onChoose () {
      try {
        this.$refs.uploadinput.click()
        let email = sessionStorage.getItem('Email')
        if (!email) { this.initWeb() }
      } catch (error) {
        console.log(error)
      }
    },
    onGetFile (e) {
      this.$emit('changeHandleLoadingShow', true)
      let filesClick = []
      if (e.target) {
        let { files } = e.target
        filesClick = [...files]
      } else {
        filesClick = [...e]
      }
      // let Servernum = this.AllServernum - this.processImagesLength
      console.log('AllServernum:', this.credits.UploadCredit)
      if (filesClick.length > this.credits.UploadCredit) {
        this.$emit('changeHandleLoadingShow', false)
        // 弹框提醒
        let msg = this.$t('You can upload up to 10 images at the same time.')
        this.$confirm(msg, {
          confirmButtonText: this.$t('Ok'),
          showCancelButton: false,
          customClass: 'confirm',
          showClose: false,
          iconClass: 'confirm-icon'
        }).then(async () => {
          let FilesList = filesClick.slice(0, this.credits.UploadCredit)
          this.FilesListUpload(FilesList)
        }).catch(() => {})
      } else {
        let FilesList = filesClick
        this.FilesListUpload(FilesList)
      }
      this.$refs.uploadinput.value = ''
    },
    async FilesListUpload (FilesList) {
      console.log('FilesList:', FilesList)
      this.uploadingList = []
      let res = await this.UploadImage(FilesList)
      if (res) {
        let counter = 1
        res.forEach(async (f, index, arr) => {
          this.UploadCos(f).then(async () => {
            await this.setWidtHeight(f)
            console.log('successAddImage:', counter, arr.length, this.successAddImage)
            if(counter === arr.length){
              this.AddImage(this.successAddImage)
              this.successAddImage = []
            }
          }).catch(() => {
            return
          }).finally(() => {
            counter++
          })
        })
      }
    },
    handleCheckedCitiesChange(value) {
      console.log('value', value)
      this.$emit('syncUpload', 'upload', [value])
      this.$emit('syncUpload', 'ready', [value.id])
    },
    CheckImgExists(imgurl) {  
      var ImgObj = new Image()
      ImgObj.src = imgurl
      //没有图片，则返回-1  
      if (ImgObj.fileSize > 0 || (ImgObj.width > 0 && ImgObj.height > 0)) {  
          return true;  
      } else {  
          return false;
      }  
    },
    // 图片限制
    async UploadFiles (files) {
      if (files.length < 1) {
        this.$emit('changeHandleLoadingShow', false)
        return false
      }
      let fileSize = files.filter(item => item.size / 1024 / 1024 > 10)
      let filesType = files.filter(item => ['image/jpg', 'image/png', 'image/bmp', 'image/jpeg', 'image/jfif', 'image/webp'].indexOf(item.type) !== -1)
      files.forEach((item) => {
        let reader = new FileReader()
        reader.readAsDataURL(item)
        reader.onload = (e) => {
          const replaceSrc = e.target.result
          let imageObj = new Image()
          imageObj.src = replaceSrc
          imageObj.onload = () => {
            if (imageObj.width === 0 && imageObj.height === 0) {
              this.$emit('changeHandleLoadingShow', false)
            }
          }
        }
        // if (ImgObj.width === 0 && ImgObj.height === 0) {
        //   this.$emit('changeHandleLoadingShow', false)
        // }
      })
      console.log('fileSize', fileSize)
      if (fileSize.length) {
        this.$emit('changeHandleLoadingShow', false)
        let msg = this.$t('Maximum photo size is 10MB.')
        this.$confirm(msg, {
          confirmButtonText: this.$t('Ok'),
          showCancelButton: false,
          customClass: 'confirm',
          showClose: false,
          iconClass: 'confirm-icon'
        }).then(async () => {
        }).catch(() => {})
        return false
      }
      if (!filesType.length) {
        this.$emit('changeHandleLoadingShow', false)
        let msg = this.$t('Please upload the correct picture format.')
        this.$confirm(msg, {
          confirmButtonText: this.$t('Ok'),
          showCancelButton: false,
          customClass: 'confirm',
          showClose: false,
          iconClass: 'confirm-icon'
        }).then(async () => {
        }).catch(() => {})
        return false
      }

      let promiseAll = []
      // 上传前发送图片宽高给列表组件
      files.forEach((item, index) => {
        promiseAll[index] = this.limitsMegapixels(item) 
      })
      await Promise.all(promiseAll)

      console.log('this.limitMegapixel', this.limitMegapixel)

      let megapixel = this.limitMegapixel.filter(m => m.width * m.height > 14000000)
      if (megapixel.length) {
        this.$emit('changeHandleLoadingShow', false)
        let msg = this.$t('Maximum photo 14 Megapixels.')
        this.$confirm(msg, {
          confirmButtonText: this.$t('Ok'),
          showCancelButton: false,
          customClass: 'confirm',
          showClose: false,
          iconClass: 'confirm-icon'
        }).then(async () => {
        }).catch(() => {})
        this.limitMegapixel = []
        return false
      } else {
        console.log('21412412412412412')
      }
      return true
    },
    ExpirationDate (expiration) {
      let date = new Date(expiration).getTime()
      return date
    },
    sleep (t) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve()
        }, t);
      })
    },
    setWidtHeight (file) {
      return new Promise((resolve) => {
        const vm = this
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => {
          const replaceSrc = e.target.result
          let imageObj = new Image()
          imageObj.src = replaceSrc
          imageObj.onload = () => {
            vm.successAddImage.push({
              id: file.id,
              name: file.name,
              width: imageObj.width,
              height: imageObj.height,
              status: 'success'
            })
            resolve()
          }
        }
      })
    },
    limitsMegapixels (file) {
      return new Promise((resolve) => {
        const vm = this
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => {
          const replaceSrc = e.target.result
          let imageObj = new Image()
          imageObj.src = replaceSrc
          imageObj.onload = () => {
            vm.limitMegapixel.push({
              width: imageObj.width,
              height: imageObj.height
            })
            resolve()
          }
        }
      })
    },
    // 上传COS
    async UploadCos (file) {
      const { AccessKeyId: accessKeyId, AccessKeySecret: accessKeySecret, SecurityToken: stsToken, Expiration: expiration } = this.cosToken
      var cos = new COS({
        getAuthorization: function (options, callback) {
          const res = {
            TmpSecretId: accessKeyId,
            TmpSecretKey: accessKeySecret,
            SecurityToken: stsToken,
            ExpiredTime: expiration
          }
          callback(res)
        }
      })
      return new Promise((reslove, reject) => {
        cos.putObject({
          Bucket: this.config.OSS_BUCKET,
          Region: this.config.OSS_REGION,
          Key: `${this.userId}/Image/${file.id}`,
          Body: file,
          StorageClass: 'STANDARD',
          onProgress: function (progressData) {
            let Progress = {
              id: file.id,
              Progress: progressData.percent
            }
            this.$emit('syncUpload', 'uploading', [Progress])
          }
        }, async(err, data) => {
          if (err) {
            // 获取COS凭证
            const result = await getCosToken({sid: this.userId}, this)
            console.log('GetSTS Success, STS: ', result)
            this.$store.commit(Mutations.setCosToken, result)
            this.$emit('syncUpload', 'failure', [file.id])
            reject()
          }
          if (data && data.statusCode === 200) {
            // this.$emit('syncUpload', 'failure', [file.id])
            this.$emit('syncUpload', 'ready', [file.id])
            reslove()
          }
        })
      })
    },
    // 通知服务器开始上传图片到OSS
    async UploadImage (files) {
      console.log('UploadImages:', files)
      // 过滤不合格的图片弹框提醒
      const result = await this.UploadFiles(files)
      if (!result) return
      let filesName = []
      files.map((a) => {
        filesName.push(a.name)
      })
      let data = {
        queryString: {
          sid: this.userId,
        },
        params: {
          images: filesName
        }
      }
      console.log('uploadImage data', data)
      const uploadImageResult = await uploadImage(data)
      console.log('uploadImage Success, uploadImageResult: ', uploadImageResult.data)
      // 重组服务器返回带id的图片
      if (uploadImageResult.code === 0) {
        files.map((a) => {
          for (let index = 0; index < uploadImageResult.data.images.length; index++) {
            let ele = uploadImageResult.data.images[index]
            if (a.name === ele.name) {
              a.id = ele.id
            }
          }
        })
        let promiseAll = []
        // 上传前发送图片宽高给列表组件
        files.forEach((item, index) => {
          promiseAll[index] = this.SyncWidtHeight(item)
        })
        Promise.all(promiseAll).then(() => {
          this.$emit('syncUpload', 'upload', this.uploadingList)
        })
        this.$emit('changeHandleLoadingShow', false)
        console.log('filesmap:', files)
        return files
      } else if (uploadImageResult.code === -9) {
        this.$emit('changeHandleLoadingShow', false)
        bus.$emit('openLogin')
      } else {
        this.$emit('changeHandleLoadingShow', false)
        let msg = this.$t('Upload failed, please upload again.')
        this.$confirm(msg, {
          confirmButtonText: this.$t('Ok'),
          showCancelButton: false,
          customClass: 'confirm',
          showClose: false,
          iconClass: 'confirm-icon'
        }).then(async () => {
        }).catch(() => {})
      }
    },
    SyncWidtHeight (file) {
      return new Promise((resolve) => {
        const vm = this
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => {
          const replaceSrc = e.target.result
          let imageObj = new Image()
          imageObj.src = replaceSrc
          imageObj.onload = () => {
            vm.uploadingList.push({
              file: file,
              id: file.id,
              thumb: replaceSrc,
              name: file.name,
              width: imageObj.width,
              height: imageObj.height,
              status: 'upload'
            })
            resolve()
          }
        }
      })
    },
    // 通知服务器上传图片到COS完成状态
    async AddImage (info) {
      console.log('AddImageInfo:', info)
      let data = {
        queryString: {
          sid: this.userId
        },
        params: {
          images: info
        }
      }
      console.log('AddImagedata', data)
      const addImageResult = await addImage(data)
      if (addImageResult.code === 0) {
        const { ImageCredit, UploadCredit, VideoCredit, SubscriptCredit } = addImageResult.data
        const credits = {
          VideoCredit: VideoCredit,
          ImageCredit: ImageCredit,
          UploadCredit: UploadCredit,
          SubscriptCredit: SubscriptCredit
        }
        this.$store.commit(Mutations.setCredits, credits)
        sessionStorage.setItem('Credits', JSON.stringify(credits))
      } else {
        // const credits = {
        //   UploadCredit: 0,
        // }
        // this.$store.commit(Mutations.setCredits, credits)
      }
      console.log('AddImage Success, addImageResult: ', addImageResult)
    },
    clearCheckedDemo () {
      this.checkedCities = {}
    },
    onFailure (e) {
      console.log('onFailure', e)
      console.log('this.uploadingList', this.uploadingList)
      this.UploadCos(e.file).then(async () => {
        await this.setWidtHeight(e.file)
        console.log('successAddImage:', this.successAddImage)
        this.AddImage(this.successAddImage)
        this.successAddImage = []
      }).catch(() => {
        return
      })
    },
    // <<<<<匿名登录逻辑>>>>>
    async Fingerprint () {
      const fpPromise = FingerprintJS.load()
      const fp = await fpPromise
      const result = await fp.get()
      const visitorId = result.visitorId
      this.visitorId = visitorId
      return visitorId
    },
    SetUid (s) {
      let str = s
      let arr = str.split('')
      arr.splice(8, 0, '-')
      arr.splice(13, 0, '-')
      arr.splice(18, 0, '-')
      arr.splice(23, 0, '-')
      str = arr.join('')
      let str1 = 'RMBG-'
      let str2 = str1 + str
      return str2
    },
    async getModelData (sid) {
      let credits = JSON.parse(sessionStorage.getItem('Credits'))
      console.log(credits)
      if (credits) {
         this.creditInfo(credits)
      }
      this.$store.commit(Mutations.setUserId, sid)
      // 获取COS凭证
      const result = await getCosToken({sid: sid}, this)
      console.log('GetSTS Success, STS: ', result)
      this.$store.commit(Mutations.setCosToken, result)
      // 获取modelList
      const { data } = await getModelList({sid})
      let modeData = data.slice(3, 8)
      this.$store.commit(Mutations.setModelList, modeData)
      console.log('GetModelList Success, modelList: ', modeData)
    },
    // 登录后储存credits
    creditInfo (data) {
      let { VideoCredit, ImageCredit, UploadCredit, SubscriptCredit } = data
      console.log(`video_credit: ${VideoCredit}, image_credit: ${ImageCredit}, upload_credit: ${UploadCredit}, SubscriptCredit: ${SubscriptCredit}`)
      const credits = {
        VideoCredit: VideoCredit,
        ImageCredit: ImageCredit,
        UploadCredit: UploadCredit,
        SubscriptCredit: SubscriptCredit
      }
      this.$store.commit(Mutations.setCredits, credits)
    },
    async initWebClose () {
      let FingerPrintId = await this.Fingerprint()
      let Fpid = this.SetUid(FingerPrintId)
      const info = await userLogin({ uid: Fpid })
      if (info.code === -2 || !info) {
        // this.$refs.loginShow.openLogin()
        this.$store.commit(Mutations.setUserAnonymous, false)
      }
    },
    async initWeb () {
      let FingerPrintId = await this.Fingerprint()
      console.log('ID :', FingerPrintId)
      let Fpid = this.SetUid(FingerPrintId)
      // 用户匿名登录 (header逻辑移到这里)
      let GoogleData = await this.googleLocation()
      console.log('GoogleData=====', GoogleData.user)
      // 先判断是否第三方账户进行登录，非第三方走匿名登录
      if (!GoogleData.user) {
        let email = sessionStorage.getItem('Email')
        let Sid = sessionStorage.getItem('Sid')
        if (email && Sid) { return }
        const info = await userLogin({ uid: Fpid })
        console.log('匿名用户', info, info.data.UID)
        if (info.code === -2 || !info) {
          // this.$refs.loginShow.openLogin()
          this.$store.commit(Mutations.setUserAnonymous, false)
        }
        if (!info.data.UID && info.code === 0) {
          let msg = this.$t('Sorry,failed to load ,please contact us .')
          this.$confirm(msg, {
            confirmButtonText: 'Ok',
            showCancelButton: false,
            customClass: 'confirm',
            showClose: false,
            iconClass: 'confirm-icon'
          }).then(async () => {
            await this.sleep(3000)
            this.initWeb()
          }).catch(() => {})
        } else {
          this.$store.commit(Mutations.setLoginstate, true)
          this.$store.commit(Mutations.setUserId, info.data.UID)
          this.$store.commit(Mutations.setUserAnonymous, true)
          this.creditInfo(info.data)
          this.getModelData(info.data.UID)
          console.log('匿名登录 成功')
        }
      } else {
        bus.$emit('GoogleData', GoogleData)
        window.history.replaceState({},'','/')
        // this.$router.push({'query': null})
        console.log('emit GoogleData', GoogleData)
      }
    },
    async googleLocation () {
      let locationPathName = location.search
      let sp = locationPathName.substring(1)
      let b = sp.split('&')
      let obj = {}
      b.map((a) => {
        let q = a.split('=')
        if (q[1]) {
          obj[q[0]] = decodeURIComponent(q[1])
        }
      })
      return obj
    },
    onDownload () {
      const lang = getCookie('lang')
      console.log('lang', lang)
      switch (lang) {
        case 'en':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'de':
          window.open('https://www.avclabs.de/download.html')
          break
        case 'fr':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'es':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'ja':
          window.open('https://www.avclabs.jp/download.html')
          break
        default:
          window.open('https://www.avclabs.com/download.html')
          break
      }
    },
    onDownloadMore () {
      const lang = getCookie('lang')
      switch (lang) {
        case 'en':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'de':
          window.open('https://www.avclabs.de/download.html')
          break
        case 'fr':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'es':
          window.open('https://www.avclabs.com/download.html')
          break
        case 'ja':
          window.open('https://www.avclabs.jp/download.html')
          break
        default:
          window.open('https://www.avclabs.com/download.html')
          break
      }
    },
    handleOpenDialog () {
      this.outVisible = true
    }
  },
  // created () {
  //   bus.$on('uploadImage', this.onChoose)
  // },
  mounted () {
    bus.$on('uploadImage', this.onChoose)
    bus.$on('onGetFile', this.onGetFile)
    bus.$on('onFailure', this.onFailure)
    bus.$on('clearCheckedDemo', this.clearCheckedDemo)
    this.$refs.select_frame.ondragleave = (e) => {
        e.preventDefault()
    }
    this.$refs.select_frame.ondrop = (e) => {
        e.preventDefault()
        const data = e.dataTransfer.files
        if (data.length < 1) {
            return
        }
        this.onGetFile(data)
    }
    this.$refs.select_frame.ondragenter = (e) => {
        e.preventDefault()
    }
    this.$refs.select_frame.ondragover = (e) => {
        e.preventDefault()
    }
    this.AllServernum = this.credits.upload_credit

    bus.$on('initWebClose', this.initWebClose)
    bus.$on('initWeb', this.initWeb)
    let sid = sessionStorage.getItem('Sid')
    if (sid) {
      this.getModelData(sid) 
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/module/upload-view.scss';
</style>