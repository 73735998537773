
export const startProgress = 'START_PROGRESS'
export const endProgress = 'END_PROGRESS'
export const setModelList = 'SET_MODEL_LIST'
export const setCurrentModelInfo = 'SET_CURRENT_MODEL_INFO'
export const setUserId = 'SET_USER_ID'
export const setCredits = 'SET_CREDITS'
export const setShowBuyTips = 'SET_SHOW_BUY_TIPS'
export const setCurrentDemo = 'SET_CURRENT_DEMO'
export const setConfig = 'SET_CONFIG'
export const setCosToken = 'SET_COC_TOKEN'
export const setLogin = 'SET_LOGIN'
export const setUserName = 'SET_USER_NAME'
export const setUserAnonymous = 'SET_USER_ANONYMOUS'
export const setWorkspaceState = 'SET_WORK_SPACE_STATE'
export const setLoginstate = 'SET_LOGIN_STATE'
export const setViewtate = 'SET_VIEW_STATE'
export const setCustomize = 'SET_CUSTOMIZE'


