<template>
<div class="fullContent AI_remover">
  <div class="upscalling_main fullContent">
    
    <div class="btn_main flexRowBetween">
      <div class="flexRowCenter">
        <i class="AIimg_icon icon_m" :class="{'disabled': imgBtnDisabled}" 
        @touchstart.stop="onOriginalDownShow"
        @touchend.stop="onOriginalUpShow" @mousedown="onOriginalDownShow" @mouseup="onOriginalUpShow"></i>
        <i class="AIimg_icon_refresh_left icon_m"  @click="onPreBtn" :class="{'disabled': preBtnDisabled}"></i>
        <i class="AIimg_icon_refresh_right icon_m" @click="onNextBtn" :class="{'disabled': nextBtnDisabled}"></i>
      </div>

      <div class="flexRowCenter mobile_zoom">
        <i class="AIimg_icon_enlarge_l icon_m" @click="zoomOutImg"  :class="{'disabled': zoomOutBtnDisabled}"></i>
        <div class="block" style="width: 200px;">
          <el-slider v-model="scaleNum" :min='1' :max='5' @change='sliderInput'></el-slider>
        </div>
        <i class="AIimg_icon_enlarge_r icon_m" @click="enlargeImg" :class="{'disabled':  enlargeBtnDisabled}"></i>
      </div>

      <div class="flexRowCenter">
        <div class="click_upload flexRowCenter ai_btn_r" @click="onRemove" :class="{'disabledRemove': disabledRemove}">
          <img :src="$Image('btn_remove.png')" alt="">
          <span class="mobile_remove"> {{ $t('Remove') }}  </span>
        </div>

        <div class="click_upload flexRowCenter ai_btn_upload_new" @click="onUploadNewImages">
          <img :src="$Image('btn_upload_new_images.png')" alt="">
          <span class="mobile_text_show">{{ $t('Upload New Images') }}   </span>
        </div>

        <div class="click_upload flexRowCenter ai_btn_r mobile_export" @click="onExport" :class="{'disabledRemove': disabledExport}">
          <img :src="$Image('btn_export.png')" alt="" >
          <span class="mobile_text_show"> {{ $t('Export') }} </span>
        </div>
      </div>

    </div>

    <el-container class="fullContent canvas_slider_num">
      <div class="mobile_brush flexColStart">

      <!-- <div class="flexRowCenter btn_main">
        <i class="AIimg_icon_enlarge_l icon_m" @click="zoomOutImg"  :class="{'disabled': zoomOutBtnDisabled}"></i>
        <div class="block" style="width: 200px;">
          <el-slider v-model="scaleNum" :min='1' :max='5' @change='sliderInputs'></el-slider>
        </div>
        <i class="AIimg_icon_enlarge_r icon_m" @click="enlargeImg" :class="{'disabled':  enlargeBtnDisabled}"></i>
      </div> -->

      <div class="flexRowBetween">


        <div class="flexRowBetween mobile_slid">
          <div :class="pointer ? '':'pointers'" @click="onPointerBtn">
            <i class="brush_icon" :class="{'brushIconDisabled': !pointer}"></i>
          </div>
          <div class="block mobile_slider">
            <el-slider v-model="canvasSliderNum" :min='10' :max='100' @change="canvasSliderNumChange" :show-tooltip='false'></el-slider>
          </div> 
          <span>{{canvasSliderNum}}px</span>
        </div>

        <div class="mobile_submenu flexColCenter">
          <i class="icon_submenu_brush"></i>
          <span>{{ $t('Brush') }}</span>
        </div>

      </div>


      </div>
      <el-aside class="modal-aside">
        <el-menu
          default-active="1"
          class="el-menu-vertical-demo"
          :default-openeds="['1']"
          @open="handleOpen"
          @close="handleClose">
          <el-submenu index="1">
            <template slot="title">
              <i class="icon_submenu_brush"></i>
              <span>{{ $t('Brush') }}</span>
            </template>
            <el-menu-item-group> 
              <div :class="pointer ? '':'pointers'" @click="onPointerBtn">
                <i class="brush_icon" :class="{'brushIconDisabled': !pointer}"></i>
              </div>
              <div class="flexRowBetween brush_span">
                <span>{{ $t('Size') }}</span>
                <span>{{canvasSliderNum}}px</span>
              </div>
              <el-menu-item index="1-1">
                <div class="block" style="width: 230px;">
                  <el-slider v-model="canvasSliderNum" :min='10' :max='100' @change="canvasSliderNumChange" :show-tooltip='false'></el-slider>
                </div>  
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <!-- <el-menu-item index="2">
            <i class="el-icon-menu"></i>
            <span slot="title">导航二</span>
          </el-menu-item>  -->
        </el-menu>
      </el-aside>
      <el-main class="fullContent" style="background-color: #fcf9fa;position: relative;">
        <div id="divScale" class="div_scale" style="touch-action: none;">
        <div v-loading="loading" id="barparent" class="barparent_canvas" ref="barparent" 
        @mousedown="AIRemoverDown"
        @mousemove="AIRemoverMove"
        @mouseup="AIRemoverUp" 
        @mouseleave='AIRemoverLeave'

        @touchstart.stop="AIRemoverDown"
        @touchmove.stop="AIRemoverMove"
        @touchend.stop="AIRemoverUp"
        @touchcancel='AIRemoverLeave'

         :style="{'position': relative, 'transform': `translate3d(${AIleft}px, ${AIheight}px, 0px) scale(${scaleNum})`}" :class="pointer ? 'pointer_a':'pointers'">
          <img v-show="originalShow" class="canvas_absolute" :src="original ? original : '' " alt="">
          <img id="canvasImg" :src="imgBlob ? imgBlob : '' " alt="">
          <canvas
          id="canvasBoard" 
          class="canvas_absolute fullContent board"
          ref="canvasBoard"
          @mousedown="canvasDown"
          @mouseup="canvasUp"
          @mousemove="canvasMove"
          @mouseleave='canvasLeave'

          @touchstart="canvasDown"
          @touchend="canvasUp"
          @touchmove="canvasMove"
          @touchcancel='canvasLeave'

          :width="canvasWidth"
          :height="canvasHeight"
          ></canvas>

          <div v-show="pointer" class="brush_m" 
          :style="{'width': `${brushW}px`, 'height': `${brushW}px`, 'top': `${brushT}px`, 'left': `${brushL}px`}"
          @mousemove="AIRemoverBrushMove"
          @touchmove="AIRemoverBrushMove"
          ></div>

        </div>
        </div>
      </el-main>

    </el-container>
  </div>
</div>

</template>

 

<script>
const { debounce, throttle } = require('lodash')
console.log('throttle:', !!debounce, !!throttle)

import bus from '@/assets/js/bus'
import { mapState } from 'vuex'
import { getCosToken } from '@/assets/js/request/api'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import * as Mutations from '@/store/mutations.js'
// import { batchDownloadFile } from "@/assets/js/utils/download.js";


export default {
  name: 'airemoveredit',
  data() {
    return {
      isPreviewImgLoading: true,
      canvasCtx: {},
      ScaleList: [1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3, 3.25, 3.5, 3.75, 4, 4.25, 4.5, 4.75, 5], // 缩放比例固定参数
      scaleNum: null, // 图片缩放比例
      enlargeBtnDisabled: false, // 放大按钮禁用状态
      zoomOutBtnDisabled: true, // 缩小按钮禁用状态,
      relative: 'relative',
      imgBlob: this.uploadImg,
      AIleft: 0,
      AIheight: 0,
      disX: 0,
      disY: 0,
      DownBtn: false,
      mouseDownX: 0,
      mouseDownY: 0,
      initX: 0,
      initY: 0,
      pointer: true,
      canvasSliderNum: 10,
      canvasFlag: false,
      rectPos: {},
      canvasWidth: 0,
      canvasHeight: 0,
      canvasStep: -1, //记录的步数
      canvasImgList: [], //用于存储历史记录的数组
      imgBtnDisabled: true,
      preBtnDisabled: true,
      nextBtnDisabled: true,
      brushW: 10,
      brushT: null,
      brushL: null,
      disabledRemove: true,
      disabledExport: true,
      imageUrlList: [],
      limitMegapixel: [],
      setImgBlob: '',
      setFile: null,
      maxSize: 1280,
      setWidth: 0,
      setHeight: 0,
      osImgCanvas: new OffscreenCanvas(1280, 1280),
      loading: false,
      resBlob: [],
      canvasStepNub: -1,
      original: this.uploadImg,
      originalShow: false,
      DownloadMaskBlob: [],
      fileName: '',
      exportUrl: false, //下载图片页面高度改变影响画板
      startX: 0,
      startY: 0
    }
  },
  props: ['uploadImg', 'FileAi', 'limitWidthHeight'],
  methods: {
    sliderInput (e) {
      if (e > 1) {
        this.zoomOutBtnDisabled = false
      } else {
        this.zoomOutBtnDisabled = true
      } 
    },
        sliderInputs (e) {
      if (e > 1) {
        this.zoomOutBtnDisabled = false
      } else {
        this.zoomOutBtnDisabled = true
      } 
    },
    onOriginalDownShow () {
      // this.originalShow = !this.originalShow
      this.originalShow = true
    },
    onOriginalUpShow () {
      this.originalShow = false
    },
    onExport () {
      // this.initWeb()
      this.exportUrl = true
      console.log('onExport:', this.resBlob[this.resBlob.length-1].src)
      this.downloadUrl(this.resBlob)
    },
    downloadUrl(resBlob) {
      if (!resBlob || resBlob.length === 0) return
      console.log('downloadUrl:', resBlob)
      resBlob.forEach(downloadObj => {
        console.log('downloadUrl resBlob forEach:', downloadObj)
        const a = document.createElement('a')
        console.log('downloadUrl resBlob a:', a)
        a.href = downloadObj.src
        console.log('downloadUrl resBlob href:', a.href)
        a.download = decodeURI(this.fileName? this.fileName : '')
        console.log('downloadUrl resBlob download:', a.download)
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        a.parentNode.removeChild(a)
     })
    },
    imgBlobFile (setImgBlob) {
      let File = new window.File([setImgBlob], Math.random(), {
          type: setImgBlob.type
      })
      return File
    },
    dataURLtoBlob (dataURI) {
      const mime = dataURI.split(',')[0].split(':')[1].split(';')[0]
      const binary = atob(dataURI.split(',')[1])
      const array = []
      for (let i = 0; i < binary.length; i += 1) {
        array.push(binary.charCodeAt(i))
      }
      return new Blob([new Uint8Array(array)], { type: mime })
    },
    // 修改图片和画板为等比例
    async getImgFromMaxSize (size, isMask) {
      return new Promise(resolve => {
        let imgData = isMask ? document.querySelector('#canvasBoard') : document.querySelector('#canvasImg')
        const osCanvasContext = this.osImgCanvas.getContext('2d')
        osCanvasContext.clearRect(0, 0, size, size)
        let scale = Math.min(size / this.setWidth, size / this.setHeight)
        if (this.setWidth < size) scale = 1
        this.osImgCanvas.width = this.setWidth * scale
        this.osImgCanvas.height = this.setHeight * scale
        console.log('imgData', imgData)
        osCanvasContext.drawImage(imgData, 0, 0, this.setWidth * scale, this.setHeight * scale)
        // console.log('osCanvasContext', osCanvasContext)
        // 修改成画布白色
        if (isMask) {
          let img = osCanvasContext.getImageData(0, 0, this.setWidth * scale, this.setHeight * scale)
          for (let i = 0; i < img.data.length; i += 4) {
            if (img.data[i] === 255) {
              img.data[i] = 255
              img.data[i + 1] = 255
              img.data[i + 2] = 255
              img.data[i + 3] = 255
            }
          }
          osCanvasContext.putImageData(img, 0, 0)
        }
        // console.log('osCanvasContext', img)
        this.osImgCanvas.convertToBlob().then((blob) => {
          resolve(blob)
        })
      })
    },
    // Remove
    async onRemove () {
      console.log('onRemove:当前画布下标', this.canvasStep, this.canvasImgList)
      // let blob = this.dataURLtoBlob(this.canvasImgList[this.canvasStep].src)
      let blob = this.canvasImgList[this.canvasStep].src
      this.DownloadMaskBlob.push(blob)
      const fd = new FormData()
      let img = await this.getImgFromMaxSize(1280)
      let img2 = await this.getImgFromMaxSize(1280, blob)
      console.log('imgimg', URL.createObjectURL(img), URL.createObjectURL(img2))
      fd.append('image', img)
      fd.append('mask', img2)
      try {
        this.loading = true
        const res = await fetch(`${this.config.INPAINTER_API_BASE_URL}inpaint`, {
          method: 'POST',
          body: fd,
        })
        if (res.ok) {
          this.setScale()
          this.canvasImgList = []
          // this.canvasStep = -1
          const blob = await res.blob()
          let b = URL.createObjectURL(blob)
          console.log('inpaint:', b)
          if (b) {
            // this.canvasStepNub++
            // console.log('canvasStepNub:', this.canvasStepNub)
            // this.clearCanvas() //清空画布
            this.imgBlob = b
            this.resBlob.push({
              src: b,
              imgRes: true
            })
            this.canvasImgList = this.resBlob
            this.canvasStep = this.resBlob.length - 1
            if (this.resBlob.length > 0) {
              this.imgBtnDisabled = false
              this.disabledExport = false
            }
            this.clearCanvas()
            console.log('onRemove res resBlob', this.resBlob, 'src: ',this.resBlob[this.resBlob.length - 1].src)
            console.log('onRemove canvasImgList', this.canvasStep, this.canvasImgList)
            this.loading = false
            // this.canvasCtx.drawImage(this.resBlob[this.canvasStepNub],0,0,this.canvasWidth, this.canvasHeight)
          }
        }
      } catch {
        throw new Error('Something went wrong on server side.')
      }

    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    // 清空画板
    clearCanvas(){
      console.log('清空画板: 1111111')
      const canvasContainer = document.querySelector('#canvasBoard')
      canvasContainer.width = canvasContainer.clientWidth
      canvasContainer.height = canvasContainer.clientHeight
    },
    // 上一步
    onPreBtn () {
      if(this.canvasStep >= 0){
        this.canvasStep--
        this.clearCanvas() //清空画布
        console.log('canvasImgList.length', this.canvasImgList)
        if (this.canvasStep === -1 && this.canvasImgList.length >= 1) {
          // this.imgBlob = this.original
          this.imgBlob = this.setImgBlob
          this.preBtnDisabled = true
          this.disabledRemove = true
          this.nextBtnDisabled = false
          return
        }
        if (this.canvasStep > -1 && !this.canvasImgList[this.canvasStep].imgRes) {
          this.disabledRemove = false
        } else {
          this.disabledRemove = true
        }
        console.log('onPreBtn:', this.canvasStep,this.canvasImgList.length)
        this.canvasReset() //重绘
        // if (this.canvasImgList[this.canvasStep].imgRes) {
        //   this.imgBlob = this.canvasImgList[this.canvasStep].src
        // } else {
        //   let img = new Image()
        //   img.src = this.canvasImgList[this.canvasStep].src
        //   img.onload = () => {
        //     this.canvasCtx.drawImage(img,0,0,this.canvasWidth, this.canvasHeight)
        //   }
        // }
      }
      if (this.canvasImgList.length-1 === this.canvasStep) {
        this.nextBtnDisabled = true
      } else {
        this.nextBtnDisabled = false
      }
      console.log(this.canvasImgList.length)
    },
    canvasReset () {
      console.log('canvasReset', this.canvasImgList)
      if (this.canvasImgList[this.canvasStep].imgRes) {
        this.imgBlob = this.canvasImgList[this.canvasStep].src
      } else {
        let img = new Image()
        img.src = this.canvasImgList[this.canvasStep].src
        img.onload = () => {
          this.canvasCtx.drawImage(img,0,0,this.canvasWidth, this.canvasHeight)
        }
      }
    },
    // 下一步
    onNextBtn () {
      if(this.canvasStep < this.canvasImgList.length-1){
        this.canvasStep++
        console.log('onNextBtn:', this.canvasStep)
        this.clearCanvas() //清空画布
        this.canvasReset() //重绘
        if (this.canvasStep === 0 && this.canvasImgList.length === 1) {
          this.imgBlob = this.canvasImgList[this.canvasStep].src
          this.preBtnDisabled = false
          this.disabledRemove = true
          this.nextBtnDisabled = true
          return
        }
        // var img = new Image()
        // img.src = this.canvasImgList[this.canvasStep].src
        // img.onload = () => {
        //   this.canvasCtx.drawImage(img,0,0,this.canvasWidth, this.canvasHeight)
        // }
      }
      if (this.canvasImgList.length-1 === this.canvasStep) {
        this.nextBtnDisabled = true
      } else {
        this.preBtnDisabled = false
      }
      if (this.canvasStep > -1 && !this.canvasImgList[this.canvasStep].imgRes) {
        this.disabledRemove = false
      } else {
        this.disabledRemove = true
      }
      // console.log(this.canvasImgList, this.canvasStep)
    },
    canvasSliderNumChange () {
      this.canvasCtx.lineWidth = this.canvasSliderNum
      this.brushW = this.canvasSliderNum
    },
    // initCanvas() {},
    // 画布事件
    canvasDown(e) {
      const canvasContainer = document.querySelector('#canvasBoard')
      let vertex = document.getElementById('canvasBoard').getBoundingClientRect()
      console.log('vertex:', vertex.left, vertex.top)
      this.startX = vertex.left
      this.startY = vertex.top
      // this.startX = e.touches ? e.touches[0].clientX : 0
      // this.startY = e.touches ? e.touches[0].clientY : 0
      // console.log( 'start:' ,this.startX, this.startY, e.target.offsetLeft, e.target.offsetTop)

      let ex = e.offsetX ? e.offsetX : e.touches[0].clientX - vertex.left
      let ey = e.offsetY ? e.offsetY : e.touches[0].clientY - vertex.top
      // console.log('777777',ex, ey)
      if (this.pointer) {
        canvasContainer.style.opacity = 0.35
        this.canvasCtx.lineWidth = this.canvasSliderNum
        this.canvasCtx.strokeStyle = 'red'
        // this.canvasCtx.strokeStyle = 'white'
        this.canvasCtx.lineJoin = 'round'
        this.canvasCtx.lineCap = 'round'
        this.canvasFlag = true
        this.canvasCtx.beginPath()
        // this.canvasCtx.moveTo(e.offsetX , e.offsetY)
        this.canvasCtx.moveTo(ex , ey)
      }
    },
    canvasMove(e) {
      let ex = e.offsetX ? e.offsetX : e.touches[0].clientX - this.startX
      let ey = e.offsetY ? e.offsetY : e.touches[0].clientY - this.startY
      if (this.canvasFlag) {
        // this.canvasCtx.lineTo(e.offsetX, e.offsetY)
        this.canvasCtx.lineTo(ex, ey)
        this.canvasCtx.stroke()
      }
    },
    canvasUp() {
      if (this.canvasFlag) {
        this.canvasHistory()
        this.canvasCtx.closePath()
        this.canvasFlag = false
      }
    },
    canvasLeave () {
      document.onmousemove = null
      document.onmouseup = null
      document.ontouchmove = null
      document.ontouchcancel = null
      this.canvasCtx.closePath()
      this.canvasFlag = false
    },
    canvasHistory () {
      const canvas = document.querySelector('#canvasBoard')
      this.canvasStep++
      console.log('this.canvasHistory', this.canvasStep)
      // if(this.canvasStep < this.canvasImgList.length){
      //   this.canvasImgList.length = this.canvasStep
      // }
      this.canvasImgList.push({
        src: canvas.toDataURL(),
        imgRes: false
      })
      this.preBtnDisabled = false
      // this.nextBtnDisabled = false
      this.resBlob = this.resBlob.filter(image => {
        return image.imgRes
      })
      console.log('this.resBlob:', this.resBlob)
      console.log('记录画笔列表', this.canvasImgList, '下标',this.canvasStep)
      this.disabledRemove = false
    },
    onPointerBtn () {
      this.pointer = !this.pointer
    },
    // 拖动事件
    AIRemoverDown (e) {
      let vertex = document.getElementById('canvasBoard').getBoundingClientRect()
      this.startX = vertex.left
      this.startY = vertex.top
      let ex = e.pageX ? e.pageX : e.touches[0].pageX
      let ey = e.pageY ? e.pageY : e.touches[0].pageY
      // this.startX = e.touches ? e.touches[0].clientX : 0
      // this.startY = e.touches ? e.touches[0].clientY : 0
      // let canvasContainer = document.querySelector('#barparent')
      // this.disX = e.clientX - canvasContainer.offsetLeft
      // this.disY = e.clientY - canvasContainer.offsetTop
      this.mouseDownX = ex
      this.mouseDownY = ey
      this.DownBtn = true
    },
    AIRemoverMove (e) {
      e.stopPropagation()

      let ox = e.offsetX ? e.offsetX : e.touches[0].clientX - this.startX
      let oy = e.offsetY ? e.offsetY : e.touches[0].clientY - this.startY
      
      this.brushT = oy - this.brushW/2
      this.brushL = ox - this.brushW/2
      let ex = e.pageX ? e.pageX : e.touches[0].pageX
      let ey = e.pageY ? e.pageY : e.touches[0].pageY
      if (this.DownBtn && !this.pointer ) {
        let mouseMoveX = ex
        let mouseMoveY = ey
        let a = parseInt(mouseMoveX) - parseInt(this.mouseDownX) + parseInt(this.initX)
        let b = parseInt(mouseMoveY) - parseInt(this.mouseDownY) + parseInt(this.initY)
        this.AIleft = a
        this.AIheight = b
      }
    },
    AIRemoverUp () {
      this.DownBtn = false
      this.initX = this.AIleft
      this.initY = this.AIheight
      document.onmousemove = null
      document.onmouseup = null
      document.ontouchmove = null
      document.ontouchcancel = null
    },
    AIRemoverLeave () {
      this.DownBtn = false
      this.initX = this.AIleft
      this.initY = this.AIheight
      document.onmousemove = null
      document.onmouseup = null
      document.ontouchmove = null
      document.ontouchcancel = null
    },
    AIRemoverBrushMove (e) {
      e.stopPropagation()
    },
    // 放大
    enlargeImg() {
      this.zoomOutBtnDisabled = false
      let index = this.ScaleList.indexOf(this.scaleNum)
      this.scaleNum = this.ScaleList[index + 1]
      this.$nextTick(() => {
        this.zoomOutBtnDisabled = this.scaleNum <= this.ScaleList[0]
        this.enlargeBtnDisabled = this.scaleNum >= this.ScaleList[this.ScaleList.length - 1]
      })
    },
    // 缩小
    zoomOutImg() {
      this.enlargeBtnDisabled = false
      let index = this.ScaleList.indexOf(this.scaleNum)
      this.scaleNum = this.ScaleList[index - 1]
      this.$nextTick(() => {
        this.zoomOutBtnDisabled = this.scaleNum <= this.ScaleList[0]
        this.enlargeBtnDisabled = this.scaleNum >= this.ScaleList[this.ScaleList.length - 1]
      })
    },
    onClose() {
      this.$modal.hide('PreviewModal')
    },
    onUploadNewImages () {
      this.$emit('onUploadNewImages')
    },
    // <<<<<匿名登录逻辑>>>>>
    async Fingerprint () {
      const fpPromise = FingerprintJS.load()
      const fp = await fpPromise
      const result = await fp.get()
      const visitorId = result.visitorId
      this.visitorId = visitorId
      return visitorId
    },
    SetUid (s) {
      let str = s
      let arr = str.split('')
      arr.splice(8, 0, '-')
      arr.splice(13, 0, '-')
      arr.splice(18, 0, '-')
      arr.splice(23, 0, '-')
      str = arr.join('')
      let str1 = 'RMBG-'
      let str2 = str1 + str
      return str2
    },
    async getModelData (sid) {
      let credits = JSON.parse(sessionStorage.getItem('Credits'))
      console.log(credits)
      if (credits) {
         this.creditInfo(credits)
      }
      this.$store.commit(Mutations.setUserId, sid)
      // 获取COS凭证
      const result = await getCosToken({sid: sid}, this)
      console.log('GetSTS Success, STS: ', result)
      this.$store.commit(Mutations.setCosToken, result)
      // 获取modelList
      // const { data } = await getModelList({sid})
      // let modeData = data.slice(3, 8)
      // this.$store.commit(Mutations.setModelList, modeData)
      // console.log('GetModelList Success, modelList: ', modeData)
    },
    // 登录后储存credits
    creditInfo (data) {
      let { VideoCredit, ImageCredit, UploadCredit, SubscriptCredit } = data
      console.log(`video_credit: ${VideoCredit}, image_credit: ${ImageCredit}, upload_credit: ${UploadCredit}, SubscriptCredit: ${SubscriptCredit}`)
      const credits = {
        VideoCredit: VideoCredit,
        ImageCredit: ImageCredit,
        UploadCredit: UploadCredit,
        SubscriptCredit: SubscriptCredit
      }
      this.$store.commit(Mutations.setCredits, credits)
    },
    async initWeb () {
      // let FingerPrintId = await this.Fingerprint()
      // console.log('ID :', FingerPrintId)
      // let Fpid = this.SetUid(FingerPrintId)
      // 用户匿名登录 (header逻辑移到这里)
      if (!this.loginShow) return
      let GoogleData = await this.googleLocation()
      console.log('GoogleData=====', GoogleData.user)
      // 先判断是否第三方账户进行登录，非第三方走匿名登录
      if (!GoogleData.user) {
        bus.$emit('openLogin')
        this.$store.commit(Mutations.setUserAnonymous, false)
        return
      } else {
        bus.$emit('GoogleData', GoogleData)
        window.history.replaceState({},'','/')
        // this.$router.push({'query': null})
        console.log('emit GoogleData', GoogleData)
      }
    },
    async googleLocation () {
      let locationPathName = location.search
      let sp = locationPathName.substring(1)
      let b = sp.split('&')
      let obj = {}
      b.map((a) => {
        let q = a.split('=')
        if (q[1]) {
          obj[q[0]] = decodeURIComponent(q[1])
        }
      })
      return obj
    },
    async setCanvasSize (f, s) {
      this.disabledExport = true
      this.exportUrl = false
      const canvasBoard = document.querySelector('#canvasBoard')
      // console.log('ffffff', f)
      // this.fileName = f.name
      // canvasContainer.style.opacity = 0.35
      this.resBlob = []
      let r = await this.resizeImageFile(f, s)
      console.log('rrrrrrrrrr', r)
      this.setWidth = r.width
      this.setHeight = r.height
      // canvasContainer.width = r.width
      // canvasContainer.height = r.height
      if (canvasBoard.clientWidth > this.maxSize || canvasBoard.clientHeight > this.maxSize) {
        this.canvasWidth = r.width
        this.canvasHeight = r.height
      } else {
        this.canvasWidth = canvasBoard.clientWidth
        this.canvasHeight = canvasBoard.clientHeight
      }
      this.setFile = r.file
      // var imgFile = r.file.target
      let setImgBlob = URL.createObjectURL(r.file)
      // console.log('AllServernum:', setImgBlob)
      this.setImgBlob = setImgBlob
      this.imgBlob = setImgBlob
      this.loading = false
      // this.setScale()
    },
    setScale () {
      const canvasContainer = document.querySelector('#canvasBoard')
      // canvasContainer.width = canvasContainer.clientWidth
      // canvasContainer.height = canvasContainer.clientHeight
      this.canvasWidth = canvasContainer.clientWidth
      this.canvasHeight = canvasContainer.clientHeight
      if (this.exportUrl) return
      console.warn(`setCanvasSize to: ${canvasContainer.clientWidth} x ${canvasContainer.clientHeight}`)
      if (this.canvasImgList.length > 0 && !this.canvasImgList[this.canvasImgList.length-1].imgRes) {
        var img = new Image()
        img.src = this.canvasImgList[this.canvasImgList.length-1].src
        img.onload = () => {
          this.canvasCtx.drawImage(img,0,0,this.canvasWidth, this.canvasHeight)
        }
      }
    },
    resizeImageFile (file, maxSize) {
      const reader = new FileReader()
      const image = new Image()
      const canvas = document.querySelector('#canvasBoard')
      const resize = () => {
        let { width, height } = image
        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width
            width = maxSize
          }
        } else if (height > maxSize) {
          width *= maxSize / height
          height = maxSize
        }

        if (width === image.width && height === image.height) {
          return { file, resized: false, width: image.width, height: image.height }
        }
        canvas.width = width
        canvas.height = height
        const ctx = canvas.getContext('2d')
        if (!ctx) {
          throw new Error('could not get context')
        }
        canvas.getContext('2d')?.drawImage(image, 0, 0, width, height)
        const dataUrl = canvas.toDataURL()
        const blob = this.dataURLtoBlob(dataUrl)
        const f = new File([blob], file.name, {
          type: file.type,
        })
        return {
          file: f,
          resized: true,
          originalWidth: image.width,
          originalHeight: image.height,
          width: width,
          height: height
        }
      }

      return new Promise((resolve, reject) => {
        if (!file.type.match(/image.*/)) {
          reject(new Error('Not an image'))
          return
        }
        reader.onload = (readerEvent) => {
          image.onload = () => resolve(resize())
          image.src = readerEvent.target.result
        }
        reader.readAsDataURL(file)
      })
    }
  },
  // components: { CanvasPhoto },
  computed: {
    ...mapState(['showBuyTips', 'credits', 'loginShow', 'config']),
    downloadArray() {
      return this.resBlob.map(item => {
        return {url: item.src, name: 'url'}
      })
    }
  },
  watch: {
    FileAi() {
    }
  },
  mounted () { 
    this.loading = true
    this.canvasImgList = []
    this.canvasStep = -1
    bus.$on('setCanvasSize', async (f) => {
      this.loading = true
      this.fileName = f.name
      this.canvasImgList = []
      this.DownloadMaskBlob = []
      this.canvasStep = -1
      // this.clearCanvas()
      let i = URL.createObjectURL(f)
      this.original = i
      this.imgBlob = i
      this.preBtnDisabled = true
      this.imgBtnDisabled = true
      this.nextBtnDisabled = true
      setTimeout(() => {
        this.setCanvasSize(f, this.maxSize)
      }, 50)
    })
    bus.$on('initWeb', this.initWeb)
    let sid = sessionStorage.getItem('Sid')
    if (sid) {
      this.getModelData(sid) 
    }
    this.throttleResizeDraw = throttle(() => {
      this.setScale()
    }, 50)
    window.addEventListener('resize', this.throttleResizeDraw)
    const canvas = document.querySelector('#canvasBoard')
    // this.canvasCtx = canvas.transferControlToOffscreen()
    this.canvasCtx = canvas ? canvas.getContext('2d') : ''
    // 初始化画布宽高
    this.fileName = this.FileAi.name
    setTimeout(() => {
      this.setCanvasSize(this.FileAi, this.maxSize)
    }, 50)
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/module/module-preview-modal.scss';
@import '@/assets/scss/module/brush-cansvas-photo.scss';
</style>
