<template>
  <el-container style="overflow: hidden !important;" element-loading-custom-class="home-loading" element-loading-text="Loading,please wait ..." id="app" class="fullContent is-vertical">
    <el-header class="header_view" style="height: 72px">
      <HeaderView @loginSuccessApp=loginSuccessApp @onLogoutLoading=onLogoutLoading ref="loginShow"></HeaderView>
    </el-header>
    <el-container>

      <el-aside class="aside_view">
        <AsideView></AsideView>
      </el-aside>


      <el-drawer
        :visible.sync="drawer"
        :direction="'ltr'"
        :with-header="false"
        :before-close="handleClose">
          <el-aside class="menu_mobile_aside">
            <AsideView></AsideView>
          </el-aside>
      </el-drawer>

      <div class="flexRowCenter app-main fullContent">
        <!-- <HomeView @toggleLoading=toggleLoading ref="loginSuccessHome">
        </HomeView> -->
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </div>
    </el-container>
  </el-container>
</template>

<script>
import { mapState } from 'vuex'
import bus from '@/assets/js/bus'
import HeaderView from './components/common/Header.vue'
import AsideView from './components/AsideView.vue'
// import HomeView from './components/HomeView.vue'
import * as Mutations from '@/store/mutations.js'
// import FingerprintJS from '@fingerprintjs/fingerprintjs'
// import { userLogin, getModelList, getCosToken } from '@/assets/js/request/api'

export default {
  name: 'app',
  data () {
    return {
      initComplete: true,
      webLoading: true,
      drawer: false
    }
  },
  components: {
    HeaderView,
    // HomeView,
    AsideView
  },
  methods: {
    handleClose(done) {
      done()
    },
    drawersApp () {
      this.drawer = !this.drawer
    },
    async onLogoutLoading (out) {
      if (out) {
        // await this.initWeb()
        // await this.$refs.loginSuccessHome.getCurrentPhoto()
      }
    },
    loginSuccessApp (s) {
      if (s) {
        // this.$refs.loginSuccessHome.getCurrentPhoto()
      } 
    },
    toggleLoading (isLoading) {
      this.webLoading = isLoading
    }
  },
  computed: {
    ...mapState(['userId', 'config', 'routerViewCut']),
  },
  created() {  
    // bus.$emit('initWeb')
    // document.body.removeChild(document.getElementById('Loading') ? document.getElementById('Loading') : '')
    let getSid = sessionStorage.getItem('Sid')
    if (getSid) {
      this.$store.commit(Mutations.setUserId, getSid)
    }
  },
  destroyed () {
    localStorage.removeItem('queryParam')
  },
  mounted () {
    bus.$emit('initWeb')
    bus.$on('drawersApp', this.drawersApp)
    this.initComplete = this.$route.params.type
    //  bus.$on('uploadImageApp', async () => {
    //    console.log('uploadImageAppuploadImageAppuploadImageAppuploadImageApp')
    //    let arg = '11111111'
    //    bus.$emit('uploadImage', arg)
    // })

    //监听前进后退事件  关闭模态框
    window.addEventListener("popstate", function(e) {
      bus.$emit('onCloseLoginRegister')
      console.log(e, 'addEventListener')
    }, false)

    window.addEventListener("beforeunload", function (e) {
      console.log(e, 'beforeunload')
      // this.$router.push({path:'/'})
    })
  }
}
</script>
